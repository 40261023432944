import React, { useEffect, useState } from "react";



import AOS from "aos";
import "aos/dist/aos.css";
import service from "../../Service/Service";
import Footer from "../footer";

import BannerSection from "../Home3/BannerSection";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "react-feather";
import { ArrowRight, ArrowRightLeft, Phone, Rocket } from "lucide-react";
import parse from 'html-react-parser';
import InputSection from "../Home3/InputSection";
import loadersvg from "../../assets/images/loader.svg";
import whatsapp from "../../assets/images/icons/whatsapp.png";
import callcenter from "../../assets/images/icons/callcenter.webp";
import Navbar from "../navbar";
import Button from "../Button/Button";


function extractTime(timeString) {
    const regex = /(\d+)(H)?(\d+)(M)?/;
    const matches = timeString.match(regex);

    let hours = 0;
    let minutes = 0;

    if (matches) {
        // Extract hours if present
        if (matches[2] === 'H') {
            hours = parseInt(matches[1]);
        }
        // Extract minutes if present
        if (matches[4] === 'M') {
            minutes = parseInt(matches[3]);
        }
    }

    return { hours, minutes };
}

const FlightDetailNew = (props) => {
    const [flights, setFlights] = useState([]);
    const [flightsOrg, setFlightsOrg] = useState([]);
    const [packagee, setPackage] = useState();
    const [packageData, setPackageData] = useState();
    const [loading, setLoading] = useState(false);
    const [paras, setParas] = useState([]);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [toast, showToast] = useState(false)
    const [error, setError] = useState('')
    const [refresh, setRefresh] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [selectedPackage, setSelectedPackage] = useState()
    const [bookinginfo, setBookinginfo] = useState()
    const [selectedSlice, setSelectedSlice] = useState([])
    const [selectedSort, setSelectedSort] = useState('least-exp')
    const [selectedAirline, setSelectedAirline] = useState(0)
    const [airlines, setAirlines] = useState([])
    const [flightNo, setFlightNo] = useState()
    const [offers, setOffers] = useState([])
    const [payment_type, setPaymentType] = useState('full-payment')
    const [firstSort, setFirstSort] = useState(false)
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {

        const token = searchParams.get('package')
        const call = searchParams.get('call')

        // if(call){
        //   setRefresh(call)
        // }
        console.log(token)//123
        if (!token) {
            localStorage.clear();
            window.location.href = '/'
            return;
        }


        setPackage(token)

    }, [window.location])

    const getAllparas = (id) => {
        service.getAllparas().then((response) => {
            let dep = response.data.filter((x) => x.package_id == id && x.para_type == 'OTHER');
            setParas(dep)

        });
    };

    useEffect(() => {

        if (packagee) {
            let pp = {}
            if (packagee == 0) {

                let params = localStorage.getItem('booking');
                if (params) {
                    setLoading(true)
                    params = JSON.parse(params);

                    console.log(params, 'params')
                    pp = params;
                    setBookinginfo(pp);
                    searchFlights(pp)
                }
            } else {
                setLoading(true)
                service.getpackagebyid({ uid: packagee }).then(ree => {
                    console.log(ree.data)
                    if (ree.data.length > 0) {
                        pp.arrival = ree.data[0].arr_iatacode;
                        pp.departure = ree.data[0].dep_iatacode;
                        setSelectedPackage(ree.data[0])
                    }
                    setBookinginfo(pp)
                    searchFlights(pp)
                })
            }

            // service.postGetFlights({
            //     package:packagee,
            //     ...pp
            // }).then(ress=>{
            //     setLoading(false)
            //     console.log(ress.data.flights);
            //     setFlights(ress.data.flights)
            //     setPackageData(ress.data.package)
            //     getAllparas(ress.data.package.uid)
            // }).catch(err=>{
            //     setLoading(false)
            // })

            console.log(pp, 'pppppp')


        }

    }, [packagee])


    useEffect(() => {
        if (selectedSlice && selectedSlice.length) {
            searchFlights('return')
        }

    }, [selectedSlice])



    const searchFlights = (pp) => {
        //   service.getSearchFlights({
        //     arrival:pp.arrival,
        //     departure:pp.departure
        // }).then(ress=>{

        //   setTimeout(()=>{setLoading(false)},1000)

        //     console.log(ress.data.flights);
        //     setFlights(ress.data)
        //    // setPackageData(ress.data.package)
        //    // getAllparas(ress.data.package.uid)
        //    //document.getElementById("flightdetail").scrollIntoView();

        // }).catch(err=>{
        //     setLoading(false)
        //  })

        let offerid = localStorage.getItem('offer')

        // service.getFlightsDetailsByOfferId({
        //     offerid
        // }).then(ress => {

        setTimeout(() => { setLoading(false) }, 1000)

        let offr = [];
        let offersFromLocal = JSON.parse(offerid)
        // if (pp && pp == 'return')
        //     offersFromLocal = offersFromLocal.return.offers;
        // else
        console.log(offersFromLocal, 'offersFromLocal');
        offersFromLocal = offersFromLocal.oneway;


        let return_offers = JSON.parse(offerid);
        return_offers = return_offers.return;

        for (let i = 0; i < offersFromLocal.length; i++) {
            let f = offersFromLocal[i];
            let p = {
                id: f.id,
                id2: i,
                origin_iatacode: f.itineraries[0].segments[0].departure.iataCode,
                origin_name: f.itineraries[0].segments[0].departure.iataCode,
                origin_city_name: f.itineraries[0].segments[0].departure.iataCode,
                origin_segments: [],
                destination_iatacode: f.itineraries[0].segments[0].departure.iataCode,
                destination_name: f.itineraries[0].segments[0].departure.iataCode,
                destination_city_name: f.itineraries[0].segments[0].departure.iataCode,
                duration: extractTime(f.itineraries[0].segments[0].duration),
                amount: f.price.total,
                airline: '',
                airline_logo: '',
                baggage: '',


            }


            if (return_offers) {
                let findReturn = return_offers.find(ff => ff.owner.name == p.airline);
                if (findReturn) {
                    p.amount = parseFloat(p.amount) + parseFloat(findReturn.total_amount)
                    p.is_return_offer_include = true
                }
            }

            // if (f.slices.length > 1) {
            //     p.return_origin_iatacode = f.slices[1].origin.iata_code;
            //     p.return_origin_name = f.slices[1].origin.name;
            //     p.return_origin_city_name = f.slices[1].origin.city_name;
            //     p.return_destination_iatacode = f.slices[1].destination.iata_code;
            //     p.return_destination_name = f.slices[1].destination.name;
            //     p.return_destination_city_name = f.slices[1].destination.city_name;
            //     p.return_duration = f.slices[1].duration;
            //     p.return_segments = f.slices[1].segments;
            // }
            offr.push(p)

        }
        setFlights(offr)
        setFlightsOrg(offr)
        setFirstSort(true);

        // setPackageData(ress.data.package)
        // getAllparas(ress.data.package.uid)
        //document.getElementById("flightdetail").scrollIntoView();

        // }).catch(err => {
        //     setLoading(false)
        // })

    }

    useEffect(() => {
        if (!loading) {
            var scrollDiv = document.getElementById("flightdetail").offsetTop;
            window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
        }

        if (flights) {

            console.log(flights, 'fffffffffffffffffffff')
            let airlineOptions = [];

            // Get unique airline names by using a Set
            const uniqueAirlines = new Set(flights.map(s => s.airline));

            // Map the unique airline names to the desired format
            airlineOptions = Array.from(uniqueAirlines).map(airline => ({ value: airline, label: airline }));

            // Set the unique airline options
            setAirlines(airlineOptions);
        }

    }, [loading, flights])

    const submit = (e) => {
        e.preventDefault()
        let booking = localStorage.getItem('bookingresult')
        if (!booking && !selectedPackage) {

            setError('Submission failed');
            showToast(true);
            setTimeout(() => { showToast(false); }, 4000)
            return;
        }



        booking = JSON.parse(booking);
        if (!name) {
            setError('Please Enter Full Name');
            showToast(true);
            setTimeout(() => { showToast(false); }, 4000)
            return;
        }
        if (!phone) {
            setError('Please Enter Phone');
            showToast(true);
            setTimeout(() => { showToast(false); }, 4000)
            return;
        }
        if (!email) {
            setError('Please Enter Email');
            showToast(true);
            setTimeout(() => { showToast(false); }, 4000)
            return;
        }

        if (selectedPackage) {
            submitOnSelectedPkg()
            return;
        }



        let params = {

            full_name: name,
            phone,
            email,
            payment_type,
            uid: booking.insertId,
            selectedSlice

        }

        service.addBooking(params).then(x => {
            // document.getElementById('my_modal_4').showModal()
            // if(setShowToast){
            //   setShowToast(true);
            // }

            localStorage.clear()
            setSubmitted(true)
            // navigate(`/`)
        }).catch(err => {
            console.log(err);
        })
    }
    function toJSONLocal(date) {
        var local = new Date(date);
        local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
    }
    const submitOnSelectedPkg = () => {


        let params = {
            departure: selectedPackage.dep_iatacode,
            arrival: selectedPackage.arr_iatacode,
            date: toJSONLocal(new Date()),
            full_name: name,
            phone,
            email,
            adult: 1,
            children: 0,
            infants: 0,
            status: 'NEW',
            reviews: '',
            description: selectedPackage.uid + ` Package`,
            return_date: toJSONLocal(new Date()),
            departure_name: selectedPackage.dep_name,
            arrival_name: selectedPackage.arrival_name
        }

        service.addBooking(params).then(x => {
            // document.getElementById('my_modal_4').showModal()
            // if(setShowToast){
            //   setShowToast(true);
            // }

            localStorage.clear()
            setSubmitted(true)
        }).catch(err => {
            console.log(err);
        })
    }

    const sortOptions = [
        { value: 'least-exp', label: 'Least Expensive' },
        { value: 'most-exp', label: 'Most Expensive' },
        { value: 'shortest-duration', label: 'Shortest duration' },
        { value: 'longest-duration', label: 'Longest duration' },
    ]

    useEffect(() => {
        let filtered = [...flightsOrg];
        if (selectedAirline != 0) {
            filtered = flightsOrg.filter(a => a.airline == selectedAirline)
        }

        if (selectedSort == 'least-exp') {
            let sf = filtered.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
            console.log(sf, filtered, flightsOrg, 'sssssssssssssssssssff')
            setFlights(sf)
        } else if (selectedSort == 'most-exp') {
            let sf = filtered.sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount));
            setFlights(sf)
        }
        else if (selectedSort == 'shortest-duration') {
            let sf = filtered.sort((a, b) => {
                // First compare hours
                if (a.duration.hours !== b.duration.hours) {
                    return a.duration.hours - b.duration.hours;
                }
                // If hours are the same, compare minutes
                return a.duration.minutes - b.duration.minutes;
            });
            setFlights(sf)
        }
        else if (selectedSort == 'longest-duration') {
            let sf = filtered.sort((a, b) => {
                // First compare hours
                if (a.duration.hours !== b.duration.hours) {
                    return b.duration.hours - a.duration.hours;
                }
                // If hours are the same, compare minutes
                return b.duration.minutes - a.duration.minutes;
            });
            setFlights(sf)
        }
    }, [selectedSort, selectedAirline, firstSort, flightsOrg])


    return (
        // loading ? <div className="absolute z-[4] bg-white w-full h-[100vh] flex justify-center items-center gap-3">
        //     {/* <Rocket className="text-white text-lg"/> */}
        //     {/* <p className="text-white text-lg">🚀 Loading...</p> */}
        //     <img src={loadersvg} className="w-[70%] md:w-[40%]" />
        // </div> :
        <div className="relative">

            <Navbar bg={1} />
            {/* <BannerSection /> */}
            <div className=" flex justify-center items-center" id="flightdetail">
            </div>
            <div className=" flex justify-center items-center mt-[80px]" >
                <p className="text-[40px] my-2 font-bold">Flights Detail</p>


            </div>

            <div className="breadcrumbs text-sm w-[90%] md:w-[80%] lg:w-[65%] mx-auto my-5 text-mLightGray">
                <ul>
                    <li><a>Flights</a></li>
                    <li><a>{bookinginfo?.departure} - {bookinginfo?.arrival}</a></li>

                </ul>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 w-[90%] md:w-[80%] lg:w-[65%] mx-auto gap-y-3 gap-x-3">

                <div className="hidden md:block">

                    <div className="bg-slate-100 border-none rounded-md p-3 space-y-1 shadow-md">
                        {bookinginfo?.return_date ? <p className="font-bold text-[16px]">Rouded Trip {bookinginfo?.arrival}</p> : <p className="font-bold text-[16px]"> One Way {bookinginfo?.arrival}</p>}
                        <div className="flex gap-2 items-center">
                            <p className="font-[500] text-[14px]">{bookinginfo?.departure}</p>
                            {bookinginfo?.return_date ? <ArrowRightLeft className="text-gray h-[14px]" /> : <ArrowRight className="text-gray h-[14px]" />}
                            <p className="font-[500] text-[14px]">{bookinginfo?.arrival}</p>


                        </div>
                        {bookinginfo?.return_date ? <p className="font-[500]">{bookinginfo?.date} - {bookinginfo?.return_date}</p> : <p className="font-[500]">{bookinginfo?.date}</p>}
                        <p className="font-[500]">{bookinginfo?.flight_class.toString().toUpperCase()}</p>
                        <button className="w-full border border-black rounded-md p-2 hover:bg-black hover:text-white" onClick={() => {
                            document.getElementById('my_modal_input_flightdetail').showModal()
                        }}>Edit Search</button>

                    </div>

                    <div className="">
                        <p className="font-bold mt-4 tracking-[1px]">Sort by</p>
                        {
                            sortOptions.map(x => {
                                return <label class="cyberpunk-checkbox-label2 my-1 tracking-[1px]">
                                    <input type="checkbox" checked={x.value == selectedSort} className="cyberpunk-checkbox" onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedSort(x.value)
                                        }
                                    }} />
                                    {x.label}</label>
                            })
                        }
                    </div>

                    <div className="p-1 flex flex-col mt-4">
                        <label className="tracking-[1px] font-bold leading-6"> Airline</label>
                        <select className="p-1 w-full h-[40px] rounded-md border border-black text-[14px]" onChange={(e) => { setSelectedAirline(e.target.value) }}>
                            <option selected value={0}>All Airlines</option>
                            {
                                airlines.map(x => {
                                    return <option value={x.value} className="text-[14px]">{x.label}</option>
                                })
                            }


                        </select>
                    </div>

                    {/* <div className="p-1 flex flex-col">
                        <label className="tracking-[1px] font-bold leading-6 mt-3">Flight No</label>
                        <input type="text" placeholder="Flight No" className="p-2 w-full  h-[40px] rounded-lg border border-black" value={flightNo} onChange={(e) => { setFlightNo(e.target.value) }} />
                    </div> */}





                </div>

                <div className="md:col-span-3 md:max-h-[90vh] overflow-y-scroll">
                    {
                        flights && selectedSlice ? <FlightsDetailSection flights={flights} selectedSlice={selectedSlice} setSelectedSlice={setSelectedSlice} bookinginfo={bookinginfo} /> : <div className="skeleton h-32 w-32"></div>
                    }


                </div>

            </div>



            <div className="my-[70px] w-[90%] md:w-[80%] mx-auto flex flex-col md:flex-row justify-between bg-secondary rounded-md p-3 relative">
                <img className="top-[-20px] h-[90px] right-0 absolute" src={callcenter} />
                <div className="col-span-2 mr-[70px] md:mr-0">
                    <p className="text-lg md:text-2xl text-white">Best Way to book is to Call us</p>
                    <p className="text-sm md:text-md text-white">We have special fares that we do not advertise online</p>
                </div>

                <div className="md:mr-[100px] mt-10 md:mt-0">
                    <p className="text-sm md:text-md text-white">Call 020 4548 3242</p>
                    <div className="flex gap-2 items-center">
                        <p className="text-sm md:text-md text-white">OR WhatsApp  </p>
                        <img src={whatsapp} className="h-[30px] w-[30px]" role="button" onClick={() => { window.open(`https://api.whatsapp.com/send/?phone=447401490100&text=`, '_blank'); }} />
                    </div>


                </div>


            </div>

            <div className="my-[70px] w-[90%] md:w-[80%] mx-auto grid grid-cols-1">
                <p className="mt-1 text-md md:text-lg font-bold leading-6 translate-2">How to Book Flights on Sky Sale?  </p>
                <p className="mt-1 text-md md:text-lg">Step 1: Choose your departure and arrival destination along with your desired dates</p>
                <p className="mt-1 text-md md:text-lg">Step 2: You will see all  multiple airlines options with prices, choose the one you prefer and click on Book Now</p>
                <p className="mt-1 text-md md:text-lg">Step 3: After you Click on Book Now fill in all the required information, Such as</p>
                <p className="mt-1 text-md md:text-lg">Your Name<br />
                    Phone Number<br />
                    Email Address </p>
                <p className="mt-1 text-md md:text-lg">Click Submit, and our Customer Support team will call as soon as possible to finalize the booking and payment procedure.</p>
                <p className="mt-1 text-md md:text-lg">Alternatively you can contact us on ( clickable phone number green color ) our customer support team will be happy to assist you throughout your travel</p>
            </div>
            <div className="my-[70px] w-[90%] md:w-[80%] mx-auto grid grid-cols-1">
                {paras.map((x) => {
                    return parse(x.description)
                })}
            </div>

            <Footer />

            <dialog id="my_modal_flightdetail" className="modal">
                <div className="modal-box w-11/12 max-w-xl ">
                    <form method="dialog">
                        {!submitted && <div>
                            <div className="p-1 flex flex-col">
                                <label className="tracking-[1px] font-[500] leading-6 ">Full Name</label>
                                <input type="text" placeholder="Type here" className="p-1 w-full  h-[40px] rounded-lg border" onChange={(e) => { setName(e.target.value) }} />
                            </div>
                            <div className="p-1 flex flex-col">
                                <label className="tracking-[1px] font-[500] leading-6 ">Phone Number</label>
                                <input type="Number" placeholder="Type here" className="p-1 w-full  h-[40px] rounded-lg border" onChange={(e) => { setPhone(e.target.value) }} />
                            </div>
                            <div className="p-1 flex flex-col">
                                <label className="tracking-[1px] font-[500] leading-6 ">Email</label>
                                <input type="text" placeholder="Type here" className="p-1 w-full  h-[40px] rounded-lg border" onChange={(e) => { setEmail(e.target.value) }} />
                            </div>
                            <div className="p-1 flex gap-4 my-2">
                                <label className={"cyberpunk-checkbox-label2"}>
                                    <input type="checkbox" checked={payment_type == 'full-payment'} className={"cyberpunk-checkbox"} onChange={(e) => {
                                        if (e.target.checked) {
                                            setPaymentType('full-payment')
                                        }
                                    }} />
                                    Full Payment</label>
                                <label className={"cyberpunk-checkbox-label2"}>
                                    <input type="checkbox" checked={payment_type == 'installment'} className={"cyberpunk-checkbox"} onChange={(e) => {
                                        if (e.target.checked) {
                                            setPaymentType('installment')
                                        }
                                    }} />
                                    Installment</label>
                            </div>
                            <button className="btn bg-primary w-full" onClick={submit}>Submit</button>
                            <p className="my-4 tracking-[1px] text-xs md:text-sm"><span className="text-red-600">Please Note:</span> This booking is subject to availability. This form will automatically be sent to one of our travel organizer official who will attend to this booking request during normal office hours. This is not a "LIVE" booking. Fares and seat availability are not guaranteed. No actual seats are currently reserved or held for you. Reservations will only be made following our advice, and your subsequent confirmation. We will contact you directly as soon as possible to confirm your travel arrangements and request payment. If the requested fare no longer be available, we will offer you the cheapest alternative.</p>
                        </div>}

                        {/* if there is a button in form, it will close the modal */}
                        {!submitted && <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => { }}>✕</button>}
                    </form>
                    {submitted && <h5 className="">Booking has been submitted, one of our agents will call you shortly.
                        For quick response, please call: (020-4548-3242)</h5>}
                    {submitted && <button className="btn bg-primary w-full mt-2" onClick={() => { navigate('/') }}>Close</button>}
                </div>
            </dialog>

            <dialog id="my_modal_input_flightdetail" className="modal">
                <div className="modal-box w-11/12 max-w-xl ">
                    <form method="dialog">
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => {

                        }}>✕</button>
                    </form>


                    <InputSection callby={`edit_search`} />



                </div>
            </dialog>
        </div>
    );
};


const FlightsDetailSection = ({ flights, selectedSlice, bookinginfo, setSelectedSlice }) => {

    const [activeIndex, setActiveIndex] = useState(null);
    console.log(bookinginfo, selectedSlice)
    return (
        flights ? flights.map((f, index) => {
            return (<div className="grid grid-cols-3 p-3 border border-mLightGray rounded-lg shadow-lg mb-3 mx-2 ">





                <div className="col-span-3 flex gap-2 items-center justify-between">
                    {/* <div className="flex gap-2 items-center">
                    <p className="font-bold text-lg">{selectedSlice.find(a => a == f.id2) ? f.return_origin_iatacode : f.origin_iatacode}</p>
                    <ArrowRight className="text-gray h-[14px]" />
                    <p className="font-bold text-lg">{selectedSlice.find(a => a == f.id2) ? f.return_destination_iatacode : f.destination_iatacode}</p>
                    </div> */}
                    <div>
                        <div className="flex gap-2 items-center">
                            <p className="font-bold text-lg">{f.origin_iatacode}</p>
                            <ArrowRight className="text-gray h-[14px]" />
                            <p className="font-bold text-lg">{f.destination_iatacode}</p>
                        </div>
                        <p className="text-md">{bookinginfo?.date}</p>
                    </div>




                    {bookinginfo?.return_date ? <div>
                        <div className="flex gap-2 items-center">
                            <p className="font-bold text-lg">{f.destination_iatacode}</p>
                            <ArrowRight className="text-gray h-[14px]" />
                            <p className="font-bold text-lg">{f.origin_iatacode}</p>
                        </div>
                        <p className="text-md">{bookinginfo?.return_date}</p>
                    </div> : ''}


                </div>

                <div className="col-span-3 flex gap-2 items-center ">
                    {/* {!selectedSlice.find(a => a == f.id2) ? <p className="text-md">{bookinginfo?.date}</p> : <p className="text-md">{bookinginfo?.return_date}</p>} */}
                    {/* <p className=" text-md">-</p>
<p className="text-md">{bookinginfo.return_date}</p> */}


                </div>
                <div className="col-span-full my-2">
                    <hr className="text-gray" />
                </div>

                {/* <div className="flex flex-col  ">
                    <p className="font-bold">Price</p>
                    <p className="mt-1">{f.amount} (GBP)</p>
                    <p className="font-bold">RETURN</p>
                </div> */}

                <div className="flex flex-col ">
                    <p className="font-bold">AirLine</p>
                    <div className="flex gap-2 items-center">
                        {/* <img src={service.baseURL+'/uploads/'+f.airline_flag} className="h-[40px] w-[40px]"/> */}
                        <img src={f.airline_logo} className="h-[40px] w-[40px]" />
                        <p>{f.airline}</p>
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <p className="font-bold">Duration</p>
                    <div className="flex gap-2 items-center">


                        <p className="mt-2">{f.duration.hours}h{f.duration.minutes}m</p>
                    </div>
                </div>
                <div className="flex md:justify-end items-center mt-2 md:mt-0">

                    <button className="rounded-md bg-primary py-2 px-3 text-sm font-[500] tracking-[1px] text-[#283747] hover:bg-[#283747] hover:text-white transition-all duration-300" onClick={() => {
                        let ss = JSON.parse(JSON.stringify(selectedSlice));
                        ss.push(f)
                        setSelectedSlice(ss);
                        document.getElementById('my_modal_flightdetail').showModal()
                    }}>Book now</button>
                </div>
                {/* <div className="join join-vertical w-full col-span-full">
                    <div className="collapse collapse-arrow join-item">
                        <input type="radio" name="my-accordion-4" className="p-0 m-0" defaultChecked />
                        <div className="collapse-title text-xl font-medium"> <div className="flex flex-col  ">
                            <p className="font-bold">Price</p>
                            <p className="mt-1">{f.amount} (GBP)</p>
                            <p className="font-bold">RETURN</p>
                        </div></div>
                        <div className="collapse-content">
                            <p>hello</p>
                        </div>
                    </div>
                </div> */}

                <div className="col-span-full">
                    <AccordionItem
                        key={index}
                        title={`Amount`}
                        content={f.amount}
                        index={index}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        flight={f}
                        showReturn={bookinginfo.return_date && selectedSlice.find(a => a == f.id2)}
                    />
                </div>





                <div className="col-span-full flex flex-col justify-center items-center mt-2">

                    <p className="text-md md:text-lg  tracking-[1px] text-center">Special Rates not Published Online</p>
                    <p className="text-md md:text-lg font-semibold tracking-[1px] text-red-700">Call Us Now</p>
                    <div className="flex gap-5 justify-center items-center">
                        <Phone className="" role="button" onClick={() => { window.location.href = 'tel:02045483242' }} />
                        <img src={whatsapp} className="h-[30px] w-[30px]" role="button" onClick={() => { window.open(`https://api.whatsapp.com/send/?phone=447401490100&text=`, '_blank'); }} />

                    </div>
                </div>
                {/* <div className="col-span-full w-full">
<hr className="text-mLightGray mt-1"/>
<div className="flex flex-col md:flex-row w-full">
{
f.itineraries[0].segments.map((s,index)=>{
return <div className="w-full md:w-[50%]">
    <p className="text-mLightGray font-bold">Sengment:{index+1}</p>
    <div className="grid grid-cols-4 gap-2">
    <p className="text-mLightGray">Arrival: </p>
    <p>{s.arrival.iataCode} - T:{s.arrival.terminal}</p>
    <p className="text-mLightGray">Departure: </p>
    <p>{s.departure.iataCode} - T:{s.departure.terminal}</p>
    </div>
</div>
})
}
</div>
</div> */}
            </div>)
        }) : <p className="text-lg text-mLightGray">No Flights</p>
    )
}

const AccordionItem = ({ title, content, index, activeIndex, setActiveIndex, flight, showReturn }) => {

    const [segments, setSegments] = useState([]);

    console.log(showReturn, 'segmentsss')

    useEffect(() => {
        if (!showReturn && flight && flight.origin_segments)
            setSegments(flight.origin_segments)
        else if (showReturn && flight && flight.return_segments) {
            setSegments(flight.return_segments)
        }
    }, [flight])


    const isActive = activeIndex === index;

    return (
        <div className="mt-2">
            <div
                className={`overflow-hidden transition-all duration-200 ease-out ${isActive ? 'max-h-screen p-4 bg-gray-50' : 'max-h-0 p-0'
                    }`}
            >
                {isActive && <div className="space-y-2">

                    {
                        segments.map(seg => {
                            return (
                                <div className="space-y-2 flex flex-col md:flex-row md:justify-between mb-4">
                                    <div className="w-[80%]">
                                        <div className="flex items-center gap-4 my-2">
                                            <div className="rounded-full h-[10px] w-[10px] md:h-[12px] md:w-[12px] border border-black"></div>
                                            <p className="leading-7 tracking-[1px] text-[12px] md:text-[14px]">Departure from <span className="font-bold text-mDarkGray">{seg.origin.name} ({seg.origin.city_name})</span> </p>
                                        </div>
                                        <div className="flex items-center gap-4">
                                            <div className="rounded-full h-[10px] w-[10px] md:h-[12px] md:w-[12px] border border-black"></div>
                                            <p className="leading-7 tracking-[1px] text-[12px] md:text-[14px]">Arrive at <span className="font-bold text-mDarkGray">{seg.destination.name} ({seg.destination.city_name})</span> </p>
                                        </div>
                                    </div>



                                    <div className="w-[20%] space-y-2">
                                        <div className="bg-black p-1 rounded-md min-w-[100px]">
                                            <p className=" text-center text-white text-[9px] md:text-[10px]"><span className="font-bold ">{seg.duration}</span> </p>
                                        </div>
                                        {seg.aircraft && <div className="bg-secondary p-1 rounded-md min-w-[150px]">
                                            <p className=" text-center text-white text-[9px] md:text-[10px]"><span className="font-bold ">{seg.aircraft?.name}</span> </p>
                                        </div>}
                                    </div>

                                </div>
                            )
                        })
                    }


                    <div>
                        <p className="font-semibold">Baggage: {flight.baggage} (Kgs)</p>
                    </div>
                </div>}
            </div>
            <div
                role="button"
                onClick={() => setActiveIndex(isActive ? null : index)}
                className="flex flex-col w-full text-left p-0 bg-gray-100 hover:bg-gray-200 focus:outline-none"
            >
                <p className=" font-bold">Amount</p>
                <span className="text-lg font-bold">{content} (GBP)</span>
            </div>

        </div>
    );
};


export default FlightDetailNew;




{/* <div className="flex md:justify-end items-center mt-2 md:mt-0">

{bookinginfo?.return_date && selectedSlice.length == 0 ? <button className="btn rounded-xl bg-black text-white hover:bg-primary" onClick={() => {
    if (!selectedSlice.length) {
        let ss = [];
        ss.push(f)
        setSelectedSlice(ss);
    }
}}>Select</button> : <button className="btn rounded-xl bg-primary " onClick={() => {
    let ss = JSON.parse(JSON.stringify(selectedSlice));
    ss.push(f)
    setSelectedSlice(ss);
    document.getElementById('my_modal_flightdetail').showModal()
}}>Book now</button>}
</div> */}