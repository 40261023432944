const keywords = [
    "flight ticket from london to delhi",
"fly london to delhi",
"lon to delhi flights",
"london to delhi airfare",
"london to mumbai flight",
"flights london to mumbai india",
"airfare from london to mumbai",
"airfare london to mumbai",
"flying to india from uk",
"london to delhi",
"lhr to delhi",
"heathrow to delhi",
"london heathrow to delhi",
"flight ticket from delhi to london",
"heathrow airport to delhi",
"heathrow delhi",
"london to mumbai",
"flights from mumbai to london",
"flight ticket from mumbai to london",
"air ticket from mumbai to london",
"airfare from mumbai to london",
"mumbai london airfare",
"manchester to delhi flight",
"flights to delhi from heathrow",
"london heathrow to mumbai",
"london heathrow to delhi flights",
"flights from lhr to delhi",
"flights from manchester to delhi india",
"flights from london heathrow to delhi india",
"heathrow to bombay",
"fly manchester to delhi",
"heathrow to mumbai flight",
"london heathrow to mumbai flight",
"flights from lhr to mumbai",
"flights from london heathrow to mumbai india",
"manchester to mumbai",
"flights to mumbai from manchester",
"manchester to bombay",
"india in uk",
"cheap flights to delhi from london",
"london new delhi flight",
"mumbai london",
"uk to india",
"london to new delhi",
"london to mumbai cheap flights",
"air india london to delhi",
"delhi tolondon",
"vistara london to delhi",
"delhi to heathrow flight",
"travel to india from uk",
"delhi to london heathrow flights",
"london to delhi flight price",
"air india london to mumbai",
"flights from delhi to lhr",
"air tickets from delhi to london heathrow",
"india to united kingdom",
"india uk",
"uk to india travel",
"manchester to delhi",
"virgin airlines delhi to london",
"air india mumbai to london",
"delhi to london virgin atlantic",
"air india from mumbai to london",
"virgin atlantic from delhi to london",
"virgin atlantic london to delhi",
"british airways mumbai to london",
"virgin london to delhi",
"mumbai to london heathrow flight",
"delhi to heathrow",
"air india delhi to london",
"india to uk",
"mumbai manchester flights",
"heathrow to delhi air india",
"british airways london to mumbai",
"delhi to london heathrow",
"flights from mumbai to manchester uk",
"virgin airlines london to delhi",
"delhi to heathrow airport",
"air india lhr to delhi",
"air india london heathrow to delhi",
"ba london mumbai",
"ba mumbai london",
"delhi heathrow",
"flights from mumbai to heathrow london",
"indian bank in uk",
"mumbai london heathrow flights",
"vfs india uk",
"virgin atlantic from london to delhi",
"cheap flights to mumbai from manchester",
"heathrow to new delhi",
"ticket to delhi from london",
"vistara delhi to london",
"london heathrow to new delhi",
"flights from delhi to manchester",
"british airways london to delhi",
"cheap flight tickets from manchester to mumbai",
"flights from uk to mumbai india",
"ba london delhi",
"virgin atlantic mumbai to london",
"manchester to new delhi flight",
"cheap flights from london to mauritius",
"flights to london from los angeles",
"cheap air ticket to london heathrow",
"cheap flights from london to cairo egypt",
"cheap flights from london to athens greece",
"cheap flights from london stansted",
"london punta cana flights",
"flights london tunis",
"london to ho chi minh city",
"flights london to georgia",
"manchester istanbul cheap flights",
"manchester amsterdam cheap flights",
"manchester paris cheap flights",
"flights to dusseldorf from london",
"cheap flights to faro from london",
"cheap flights to cyprus from london",
"cheap flights to malaga from manchester",
"cheap flights to goa from london",
"cheap flights to malta from london",
"cheap flights to budapest from london",
"cheap flights to prague from london",
"cheapest way to get from london to paris",
"cheap flights to milan from london",
"cheap flights to belfast from london",
"manila to london flights",
"cheap flights to egypt from london",
"alicante to manchester",
"cheap flights to los angeles from london",
"cheap flights to tirana from london",
"cheapest way from london to paris",
"cheap flights to barcelona from manchester",
"cheapest way to get to london",
"cheap fares to london",
"flights from cairo to london",
"london to tokyo direct flight",
"london burgas flights",
"direct flights from birmingham",
"direct flights to thailand from london",
"flights to bourgas from london",
"cheap flights london to miami",
"cheap flights from london to hong kong",
"cheap flights from london to ghana",
"flights to ankara from london",
"flights malaga to birmingham",
"flights london to santiago chile",
"flights to bacau from london",
"cheap flights london to sydney australia",
"flights to perpignan from london",
"cheap flights london to la",
"cheapest flight from london to kochi",
"flights from london to burgas",
"flight pisa to london",
"cheapest flight for london",
"cheap flights to cochin from london",
"london to bermuda flight",
"london to anywhere flights",
"manchester helsinki",
"cheap flight tickets from london to hong kong",
"fly to bruges from manchester",
"london to cheap flights",
"cheap flights from lagos to london",
"cheap flights to canada from london",
"cheap flights to larnaca from london",
"cheap flights to vancouver from london",
"cheap flights to marrakech from london",
"cheap flights to albania from london",
"cheap flights to nice from london",
"cheap flights to geneva from london",
"cheap flights to zurich from london",
"cheap flights from london to accra",
"cheap flights to frankfurt from london",
"cheap travel to london",
"cheap flights manchester to dublin",
"cheap flights from manchester to anywhere",
"london to colombo cheap flights",
"london to cebu flights",
"cheap flight to tehran from london",
"cheap flights from london to sri lanka colombo",
"cheap flights from london to harare",
"cheap flights from london to harare zimbabwe",
"cheap flights london to munich",
"cheap flights london to nairobi",
"cheap flights london to bali",
"cheap flights to larnaca cyprus from london",
"cheap flight ticket from lagos to london",
"cheap flights london to australia",
"direct flights to vietnam from london",
"manchester to usa direct flights",
"direct flights to manchester",
"ticket to amsterdam from london",
"las vegas to london flight",
"birmingham amsterdam cheap flights",
"direct flights to london",
"cheap flights to ibiza from london",
"cheap flights to alicante from birmingham",
"london to hong kong direct flight",
"last minute flights from london",
"london to edmonton flights",
"cheap flights to boston from london",
"cheap flights to copenhagen from london",
"colombo to london flights",
"cheap flights to vienna from london",
"cheap flights london to melbourne",
"london to paris cheap",
"cheap flights to kuala lumpur from london",
"cheap flights to greece from london",
"cheap tickets to paris from london",
"cheap flights to london gatwick",
"cheap flights to germany from london",
"cheap flights to kathmandu from london",
"cheap flights to algeria from london",
"flights jersey to london",
"cheap flights to mumbai from manchester",
"ticket london to tirana",
"cyprus to london flights",
"cheap flights from london to anywhere",
"cheap flights aberdeen to london",
"london to mauritius direct flights",
"flights manchester to pula",
"cheap flights from london to singapore",
"cheap flights from london to melbourne australia",
"cheap flight from london to kuala lumpur malaysia",
"biman bangladesh airlines ticket price london to sylhet",
"flights from london to freetown",
"cheap flight from london to boston usa",
"perth to london qantas",
"manchester to turkey cheap flights",
"flights from london to heathrow",
"london to amritsar direct flights",
"cheap fly to algeria from london",
"cheap flight tickets from manchester to mumbai",
"fly derry to birmingham",
"vueling airlines manchester terminal",
"flights to london from south africa",
"cheap flights to malaga from birmingham",
"calgary to london flights",
"cheap flights from istanbul to london",
"cheap tickets to manchester",
"london to mumbai flight ticket price",
"cheap flights to stockholm from london",
"cheap flights to venice from london",
"cheap flights to naples from london",
"cheap flights from amsterdam to london",
"cheap flights to porto from london",
"cheap flights to tunisia from london",
"london to cancun direct flights",
"cheap flights to san francisco from london",
"cheap flights to phuket from london",
"london to vancouver direct flights",
"flights to ercan from london",
"flights to milan linate from london",
"cheap flights manchester to rome",
"cheap flight to iran from london",
"london to seychelles direct flight",
"cheap flights to algiers from london",
"toronto manchester flights",
"cheap tickets to manchester uk",
"cheap day return to london",
"cheap flights from manchester ryanair",
"flights from jfk to london heathrow",
"cheap flights to scotland from london",
"cheap flights from manchester to london",
"cheap flight to erbil from london",
"cheap flights from london to krakow",
"manchester to vancouver direct flights",
"cheap flights to london stansted",
"newark to london heathrow",
"london suceava flights",
"direct flights london to jamaica",
"london to japan cheap flights",
"direct flights london to costa rica",
"ryanair cheap flights from manchester",
"london to banjul flights",
"flights to vigo from london",
"flights to london from harare",
"cheap flight to kabul from london",
"flights to palanga from london",
"cheap flight tickets from istanbul to london",
"belfast to manchester cheap flights",
"london to fez flights",
"plane ticket to london from south africa",
"cheap places to fly to from london",
"flights from london to zaragoza",
"cheap flights ryanair from manchester",
"cheap ryanair flights from manchester",
"flights from london to minsk",
"ryan air cheap flights from manchester",
"cheap flights to izmir from london",
"cheap flights to portugal from london",
"cheap flights to gambia from london",
"cheap flights from paris to london",
"cheap flights to faro from manchester",
"cheap flights from lisbon to london",
"cheap flights to entebbe from london",
"london to milan linate",
"cheap flights to agadir from london",
"cheap flights to seville from london",
"london to delhi direct flight",
"cheap flights to ireland from london",
"london discount tickets",
"flight ticket from london to delhi",
"fly london to delhi",
"lon to delhi flights",
"london to delhi airfare",
"london to mumbai flight",
"flights london to mumbai india",
"airfare from london to mumbai",
"airfare london to mumbai",
"flying to india from uk",
"london to delhi",
"lhr to delhi",
"heathrow to delhi",
"london heathrow to delhi",
"flight ticket from delhi to london",
"heathrow airport to delhi",
"heathrow delhi",
"london to mumbai",
"flights from mumbai to london",
"flight ticket from mumbai to london",
"air ticket from mumbai to london",
"airfare from mumbai to london",
"mumbai london airfare",
"manchester to delhi flight",
"flights to delhi from heathrow",
"london heathrow to mumbai",
"london heathrow to delhi flights",
"flights from lhr to delhi",
"flights from manchester to delhi india",
"flights from london heathrow to delhi india",
"heathrow to bombay",
"fly manchester to delhi",
"heathrow to mumbai flight",
"london heathrow to mumbai flight",
"flights from lhr to mumbai",
"flights from london heathrow to mumbai india",
"manchester to mumbai",
"flights to mumbai from manchester",
"manchester to bombay",
"india in uk",
"cheap flights to delhi from london",
"london new delhi flight",
"mumbai london",
"uk to india",
"london to new delhi",
"london to mumbai cheap flights",
"air india london to delhi",
"delhi tolondon",
"vistara london to delhi",
"delhi to heathrow flight",
"travel to india from uk",
"delhi to london heathrow flights",
"london to delhi flight price",
"air india london to mumbai",
"flights from delhi to lhr",
"air tickets from delhi to london heathrow",
"india to united kingdom",
"india uk",
"uk to india travel",
"manchester to delhi",
"virgin airlines delhi to london",
"air india mumbai to london",
"delhi to london virgin atlantic",
"air india from mumbai to london",
"virgin atlantic from delhi to london",
"virgin atlantic london to delhi",
"british airways mumbai to london",
"virgin london to delhi",
"mumbai to london heathrow flight",
"delhi to heathrow",
"air india delhi to london",
"india to uk",
"mumbai manchester flights",
"heathrow to delhi air india",
"british airways london to mumbai",
"delhi to london heathrow",
"flights from mumbai to manchester uk",
"virgin airlines london to delhi",
"delhi to heathrow airport",
"air india lhr to delhi",
"air india london heathrow to delhi",
"ba london mumbai",
"ba mumbai london",
"delhi heathrow",
"flights from mumbai to heathrow london",
"indian bank in uk",
"mumbai london heathrow flights",
"vfs india uk",
"virgin atlantic from london to delhi",
"cheap flights to mumbai from manchester",
"heathrow to new delhi",
"ticket to delhi from london",
"vistara delhi to london",
"london heathrow to new delhi",
"flights from delhi to manchester",
"british airways london to delhi",
"cheap flight tickets from manchester to mumbai",
"flights from uk to mumbai india",
"ba london delhi",
"virgin atlantic mumbai to london",
"manchester to new delhi flight",
"manchester to islamabad",
"manchester to pakistan islamabad",
"london to lahore flights",
"flights from london to lahore pakistan",
"fly london to lahore",
"london to islamabad",
"london to pakistan islamabad",
"london to lahore",
"manchester to lahore",
"manchester to islamabad flights",
"manchester to pakistan lahore",
"london to pakistan lahore",
"flights from manchester to islamabad pakistan",
"islamabad flights from manchester",
"london to lahore pakistan",
"manchester flights to islamabad",
"birmingham to islamabad",
"london to islamabad flights",
"flights from london to pakistan islamabad",
"flights london islamabad",
"birmingham islamabad",
"fly london to islamabad",
"cheap flights from manchester to islamabad",
"cheap flights from manchester to pakistan islamabad",
"cheap flights to islamabad from manchester uk",
"london to karachi",
"heathrow to islamabad",
"manchester to lahore flights",
"london to lahore cheap flights",
"flights to karachi from london",
"cheap flights from london to lahore pakistan",
"flights from manchester to lahore pakistan",
"cheap flights london lahore",
"cheap flights london to lahore pakistan",
"flights london karachi",
"islamabad to manchester",
"cheap flights to islamabad from london",
"heathrow to lahore",
"cheap flights from manchester to lahore",
"cheap flights from london to pakistan islamabad",
"cheap flights from manchester to lahore pakistan",
"islamabad manchester",
"cheap flights to karachi from london",
"flights from birmingham to islamabad",
"bhx to islamabad",
"flights from birmingham to islamabad pakistan",
"london heathrow to islamabad",
"flights from islamabad to london",
"cheap flights from birmingham to islamabad",
"london heathrow to pakistan islamabad",
"heathrow airport to islamabad",
"cheap flights from birmingham to islamabad pakistan",
"air fare from islamabad to london",
"cheap flights from birmingham to pakistan islamabad",
"cheap flights to islamabad from birmingham uk",
"heathrow islamabad",
"islamabad flights to london",
"birmingham to lahore",
"lahore to london",
"islamabad to london",
"flight from lahore to london",
"air ticket lahore to london",
"air fare from lahore to london",
"flights from islamabad to manchester",
"manchester to islamabad ticket price",
"islamabad to heathrow",
"tickets from manchester to islamabad",
"islamabad to birmingham flights",
"islamabad to london heathrow",
"london to islamabad direct flights",
"birmingham to lahore flights",
"flights birmingham to lahore",
"lahore to manchester",
"glasgow to lahore",
"pia manchester to islamabad",
"gatwick to islamabad",
"london gatwick to islamabad",
"pia manchester islamabad",
"manchester to islamabad direct flights",
"flights from heathrow to islamabad",
"karachi to london flight",
"direct flights to lahore from london",
"london heathrow to islamabad flights",
"flights to islamabad from london heathrow",
"direct flight london to lahore",
"air fare from karachi to london",
"air ticket from karachi to london",
"flight from uk to islamabad",
"karachi to london",
"cheap flights from islamabad to manchester",
"lahore to manchester flights",
"london to islamabad ticket price",
"cheap flights from heathrow to islamabad",
"heathrow to lahore flights",
"birmingham to islamabad ticket price",
"cheap flights from london heathrow to islamabad pakistan",
"cheap flights to islamabad from london heathrow",
"london to lahore tickets",
"cheap flights from birmingham to lahore",
"london heathrow to lahore flights",
"flights to new york from london",
"london uk to new york",
"london england to new york",
"london to new york city",
"flights to nyc from london",
"fly london to new york",
"flight ticket from london to new york",
"nyc flights from london",
"airfare london to new york",
"flights from manchester england to new york",
"manchester to new york airlines",
"manchester to newyork",
"manchester uk to new york",
"manchester england to new york",
"uk to usa flight",
"plane ticket from uk to usa",
"flights from the uk to usa",
"air tickets to usa from uk",
"airfare from uk to usa",
"flights from united kingdom to united states",
"plane ticket from uk to us",
"plane tickets uk to usa",
"cheap flights to new york from london",
"cheap flights london to nyc",
"fly new york to london",
"airfare to london from nyc",
"fly nyc to london",
"flights from new york to london",
"low cost flights london to new york",
"plane tickets to london from new york",
"airfare new york to london",
"fly from nyc to london",
"plane tickets from london to new york",
"flights from new york to london england",
"new york to london flight ticket",
"flights new york city to london",
"flights from new york to london uk",
"flights from nyc to london england",
"cheap flights to usa from uk",
"flights from uk to boston usa",
"flights from uk to nashville usa",
"low cost flights to usa from uk",
"budget airlines to usa from uk",
"cheapest airline uk to usa",
"flights to america from uk cheap",
"flights from uk to california usa",
"london new york city",
"aer lingus manchester to new york",
"flights from london to jfk new york",
"london to new york jfk",
"flights to usa from manchester uk",
"direct flights from manchester uk to new york",
"fly manchester to new york direct",
"ba london to new york",
"tickets to new york from london",
"new york to manchester flights",
"tickets to nyc from london",
"flights to manchester uk from new york",
"london to new york price",
"flights from new york to manchester england",
"fly new york to manchester",
"flights to uk from usa",
"flights to united kingdom from usa",
"airfare to uk from usa",
"direct flights to usa from manchester uk",
"flights from united states to united kingdom",
"flights from usa to united kingdom",
"manchester to new york jfk",
"usa uk flights",
"manchester new york flights",
"virgin atlantic london to new york",
"london to new york virgin",
"virgin airlines london to new york",
"holiday to new york from manchester",
"manchester to new york virgin",
"virgin atlantic manchester to new york",
"cheapest day to fly to usa from uk",
"jfk to lon",
"flights from uk to philadelphia usa",
"flights uk to memphis usa",
"virgin airlines manchester to new york",
"new york to manchester uk",
"new york to manchester",
"tickets to usa from uk",
"cheapest place to fly to in usa from uk",
"cheapest place to fly in usa from uk",
"cheapest place to fly to usa from uk",
"cheapest places to fly to in usa from uk",
"direct flights from uk to boston usa",
"new york to manchester england",
"cheap flights to london from nyc",
"cheap flights new york to london",
"jetblue london to new york",
"fly to usa from uk",
"cheap flights from london to jfk new york",
"london to new york flight price",
"british airways new york to london",
"london to new york flight fare",
"ba flights london to new york",
"air ticket london to new york",
"london to new york flight cost",
"london new york flight price",
"flights to usa from birmingham uk",
"flights london to new york british airways",
"flights to boston usa from birmingham uk",
"ba new york to london",
"cheap flight tickets from new york to london",
"flight cost london to new york",
"flights to miami from london",
"fly london to miami",
"flights from london to miami florida",
"flights from london to miami usa",
"london to miami",
"flight to orlando from manchester",
"flights from manchester to orlando florida",
"flights from manchester uk to orlando florida",
"manchester to orlando",
"flights to florida from manchester",
"flights to florida from london",
"flights manchester to miami",
"manchester to orlando florida",
"flights to florida from manchester uk",
"flights florida from manchester",
"flights from manchester uk to miami",
"fly manchester to florida",
"london to florida",
"miami manchester",
"manchester to miami",
"miami to london flights",
"london flights from miami",
"fly miami to london",
"flights from miami to london england",
"airfare miami to london",
"manchester to florida",
"miami to london",
"cheap flights london to miami",
"flights from orlando to manchester uk",
"flight from orlando to london england",
"flights from orlando florida to london england",
"flights orlando manchester",
"flights london to fort lauderdale",
"london to miami british airways",
"manchester to miami direct flights",
"manchester to tampa florida",
"flights from manchester to tampa florida",
"flights from manchester uk to tampa florida",
"direct flights manchester uk to miami",
"flight london st petersburg",
"fly manchester to miami direct",
"flights to london from florida",
"british airways miami to london",
"ba miami london",
"florida london flights",
"orlando fl to manchester uk",
"plane tickets from florida to london",
"london to orlando direct flights",
"florida to london",
"flights from miami to manchester",
"flights to los angeles from london",
"flights to la from london",
"fly london to los angeles",
"fly to los angeles from london",
"london flights to los angeles",
"flights from london to los angeles california",
"london flights to la",
"london to los angeles",
"los angeles from london",
"flights to las vegas from london",
"fly london to las vegas",
"fly from london to las vegas",
"london to las vegas",
"london to san fransisco",
"flights to las vegas from manchester",
"flights to vegas from manchester",
"flights to las vegas from manchester uk",
"fly from manchester to las vegas",
"fly manchester to vegas",
"london to las vegas flight duration",
"ca from london",
"manchester to las vegas",
"manchester uk to las vegas",
"flights to lax from london",
"flights to los angeles from manchester",
"heathrow to vegas",
"london heathrow to las vegas",
"flight from manchester to la",
"fly manchester to la",
"fly manchester to los angeles",
"direct flights to las vegas from manchester",
"direct flights to vegas from manchester",
"fly manchester to las vegas direct",
"london to california",
"manchester to los angeles",
"flights to lax from london heathrow",
"flights to california from london",
"flights from los angeles to london",
"flights to vegas from heathrow",
"la to london flights",
"flights to las vegas from london heathrow",
"airfare los angeles to london",
"london heathrow to las vegas flights",
"flights from los angeles to london england",
"flights to san fransisco from manchester",
"fly from los angeles to london",
"fly los angeles to london",
"los angeles flight to london",
"manchester to san fransisco",
"los angeles to london",
"cheap flights to los angeles from london",
"cheap flight london to la",
"cheap flights from london to los angeles ca",
"flights from san fransisco to london",
"los angeles to london england",
"flights from lax to london",
"flights to london from las vegas",
"gatwick to las vegas",
"gatwick to vegas",
"flights from las vegas to london england",
"airfare from lax to london",
"fly from lax to london",
"london gatwick to las vegas",
"plane tickets from lax to london",
"airline tickets from lax to london",
"airfare from las vegas to london",
"airfare las vegas to london",
"flights from lax to london uk",
"fly las vegas to london",
"las vegas flights london",
"las vegas flights to london",
"las vegas to london",
"san fransisco to london",
"virgin manchester to las vegas",
"flights from gatwick to las vegas",
"lax to lon",
"flights to vegas from gatwick",
"flights from manchester to los angeles lax",
"virgin atlantic manchester to las vegas",
"flights from london gatwick to las vegas",
"virgin atlantic manchester las vegas",
"london to las vegas direct flights",
"cheap flights to las vegas from london",
"flights from las vegas to london heathrow",
"flights las vegas to london heathrow",
"flights from vegas to heathrow",
"london las vegas direct flights",
"california to london",
"direct flight london las vegas",
"fly london to las vegas direct",
"vegas to heathrow flights",
"cheap flights to lax from london",
"las vegas to london heathrow",
"london to san jose flights",
"flights from london to san jose california",
"british airways heathrow to vegas",
"vegas to heathrow",
"manchester to los angeles direct flight",
"flights from las vegas to manchester uk",
"flights to london from california",
"umrah package from uk",
"umrah visa uk",
"umrah visa price uk",
"umrah packages from birmingham uk",
"umrah packages from manchester uk",
"cost of hajj from uk",
"umrah from uk",
"hajj from uk",
"hajj united kingdom",
"umrah package from london",
"umrah cost from uk",
"hajj 2024 packages uk",
"cheapest umrah package from uk",
"umrah packages leicester",
"leicester umrah packages",
"umrah from london",
"best umrah packages uk",
"best umrah travel agency uk",
"umrah flights from uk",
"umrah ticket price from uk",
"umrah visa cost uk",
"umrah tickets from uk",
"umrah tickets uk",
"umrah packages luton",
"umrah visa uk price",
"umrah visa application uk",
"umrah travel agents london",
"umrah agents in london",
"umrah packages from edinburgh",
"flights to umrah from london",
"best umra package",
"umra ticket price",
"flights to dubai from london",
"flight london to dubai",
"fly to dubai from london",
"uk to dubai flights",
"cheap flights to dubai from london",
"cheap flight tickets from london to dubai",
"cheap air tickets from london to dubai",
"flights to dubai from london heathrow",
"gatwick to dubai",
"london gatwick to dubai",
"lhr to dubai flights",
"flights to dubai from manchester",
"fly to dubai from manchester",
"flights to dubai from manchester uk",
"manchester to dubai",
"london to abu dhabi",
"flights to abu dhabi from london",
"fly london to abu dhabi",
"abu dhabi from london",
"london flights to abu dhabi",
"fly from london to abu dhabi",
"heathrow to abu dhabi",
"london heathrow to abu dhabi",
"heathrow abu dhabi",
"flights from abu dhabi to london",
"london to abu dhabi etihad",
"abu dhabi flights from uk",
"etihad airways london to abu dhabi",
"etihad heathrow to abu dhabi",
"etihad lhr to abu dhabi",
"etihad airways heathrow to abu dhabi",
"etihad london heathrow to abu dhabi",
"london to port harcourt",
"manchester to port harcourt",
"cheap flights from london heathrow to port harcourt",
"flights from manchester to port harcourt",
"london ouagadougou flights",
"london to ouagadougou",
"manchester to ouagadougou",
"london to lome",
"manchester to lome",
"london to kumasi",
"manchester to kumasi",
"london to monrovia",
"flights from london to monrovia liberia",
"flights to monrovia liberia from london",
"london to monrovia flights",
"london to conakry",
"flights to conakry from london",
"flights from london to conakry",
"cheap flights from manchester to conakry",
"manchester to conakry",
"fly london to banjul",
"flights to banjul from manchester",
"flights from manchester to banjul gambia",
"london to banjul",
"cheap flights to banjul from london",
"manchester to banjul",
"cheap flights from manchester to banjul",
"flights to banjul from gatwick",
"flights from london gatwick to banjul",
"flights from gatwick to banjul gambia",
"gatwick to banjul",
"london gatwick to banjul",
"direct flights to banjul from london",
"london dar es salaam flights",
"flights from london to dar es salaam tanzania",
"fly london to dar es salaam",
"manchester to dar es salaam",
"heathrow to dar es salaam",
"london heathrow to dar es salaam",
"cheap flight to dar es salaam from london",
"cheap flights to dar es salaam from london heathrow",
"cheap flights to dar es salaam from heathrow",
"flights to dar es salaam from manchester",
"cheap flights from manchester to dar es salaam",
"flights from heathrow to dar es salaam",
"british airways london to dar es salaam",
"london to cotonou",
"manchester to cotonou",
"london gaborone flights",
"london to gaborone",
"cheap flights from manchester to gaborone",
"manchester to gaborone",
"london to mogadishu",
"london mogadishu flights",
"cheap flights to mogadishu somalia from london",
"manchester to mogadishu",
"london to somalia mogadishu",
"london to mogadishu qatar airways",
"heathrow to mogadishu",
"ethiopian airlines london to mogadishu",
"london heathrow to mogadishu",
"flights from london heathrow to mogadishu",
"fly london to mogadishu",
"london to kano",
"manchester to kano",
"london to douala",
"london douala flights",
"flights from london to douala cameroon",
"flight london douala",
"cheap flights to douala cameroon from london",
"cheap flights from london to douala",
"cheap flights from manchester to douala cameroon",
"cheap flights to douala from manchester",
"manchester to douala",
"flights from manchester to douala",
"cheap flights from london heathrow to douala",
"london harare flights",
"flights london to harare zimbabwe",
"cheap flights to harare from london",
"london to harare",
"flights to harare from manchester",
"heathrow to harare flights",
"flights from heathrow to harare",
"london heathrow to harare flights",
"flights from heathrow to harare zimbabwe",
"flights to harare from london heathrow",
"flights from london heathrow to harare",
"cheap flights to harare zimbabwe from london heathrow",
"cheap flights to harare from manchester",
"cheapest flights to harare from london heathrow",
"cheap flights from manchester to harare zimbabwe",
"cheap flights from heathrow to harare zimbabwe",
"cheap flights from manchester uk to harare zimbabwe",
"cheap flights from heathrow to harare",
"heathrow to harare cheap flight",
"manchester to harare",
"london heathrow to harare",
"flights from london heathrow to harare zimbabwe",
"london dakar flights",
"fly london to dakar",
"london to dakar",
"london to dakar senegal",
"cheap flight to dakar senegal from london",
"manchester to dakar",
"flights from manchester to dakar",
"manchester to dakar flights",
"london mombasa flight",
"london to mombasa",
"manchester to mombasa",
"flights to mombasa from manchester",
"heathrow to mombasa",
"london heathrow to mombasa",
"cheap flights from manchester to mombasa kenya",
"cheap flights from manchester to mombasa",
"cheap flights from london heathrow to mombasa",
"cheap flights to mombasa from heathrow",
"flights to mombasa from london heathrow",
"flights to mombasa from heathrow",
"london to mombasa kenya airways",
"manchester to mombasa flight time",
"flights to zanzibar from london",
"fly to zanzibar from london",
"fly london to zanzibar",
"manchester to zanzibar",
"flights to zanzibar from manchester",
"cheap flights to zanzibar from london",
"flights to zanzibar from london heathrow",
"london to zanzibar",
"london flights to zanzibar",
"manchester zanzibar flights",
"cheap flights london zanzibar",
"london zanzibar cheap flights",
"direct flights to zanzibar from london",
"flights to abuja from london",
"flights london to abuja nigeria",
"london to abuja",
"london to abuja nigeria",
"cheap flights from london to abuja",
"cheap flights from london to abuja nigeria",
"london to abuja british airways",
"ba london to abuja",
"manchester to abuja",
"manchester to abuja flights",
"flight from manchester to abuja nigeria",
"heathrow to abuja",
"london heathrow to abuja",
"lhr to abuja",
"british airways flight to abuja",
"ba flights to abuja nigeria",
"cheap flights from manchester to abuja",
"cheap flights from manchester to abuja nigeria",
"british airways flight from abuja to london",
"flights to kigali from london",
"flights from london to kigali rwanda",
"flights to kigali rwanda from london",
"london to kigali",
"london to kigali rwandair",
"rwandair london to kigali",
"manchester to kigali",
"cheap flights from manchester to kigali",
"manchester to kigali flights",
"heathrow to kigali",
"flights to nairobi from london",
"flights from london to kenya nairobi",
"fly london to nairobi",
"fly london nairobi",
"london flights to nairobi",
"london to nairobi",
"london to kenya nairobi",
"london to nairobi kenya",
"cheap flights to nairobi from london",
"cheap air ticket from london to nairobi",
"cheap flights from london to nairobi kenya",
"heathrow to nairobi",
"london heathrow to nairobi",
"heathrow nairobi",
"flights to nairobi from heathrow",
"kenya airways london to nairobi",
"flights from manchester to nairobi",
"flights to nairobi from london heathrow",
"flights from manchester to nairobi kenya",
"heathrow nairobi flights",
"flights from heathrow to nairobi kenya",
"flights heathrow nairobi",
"fly manchester to nairobi",
"kq from london to nairobi",
"kq london to nairobi",
"manchester to nairobi",
"london to nairobi british airways",
"ba from london to nairobi",
"ba london nairobi",
"flights to nairobi from uk",
"flights from uk to nairobi kenya",
"british airways nairobi to london",
"direct flights from london to nairobi",
"kenya airways nairobi to london",
"ba nairobi london",
"kq nairobi to london",
"cheap flights to nairobi kenya from london heathrow",
"cheap flights to nairobi from heathrow",
"cheap flights to nairobi from london heathrow",
"heathrow to nairobi kenya airways",
"heathrow to nairobi british airways",
"cheap flights to nairobi kenya from manchester",
"ba heathrow to nairobi",
"british airways london heathrow to nairobi",
"kenya airways lhr to nairobi",
"cheap tickets to nairobi from london",
"tickets from london to nairobi",
"london to nairobi tickets",
"kq flights from london to nairobi",
"gatwick to nairobi",
"london to addis ababa",
"flight to addis ababa from london",
"flights from london to addis ababa ethiopia",
"cheap flights to addis ababa from london",
"cheap flights from london to addis ababa ethiopia",
"london to addis ababa ethiopian airlines",
"london heathrow to addis ababa",
"heathrow to addis ababa",
"flights to durban from london",
"flights london to durban south africa",
"fly london to durban",
"london to durban",
"london to durban south africa",
"flights from manchester to durban",
"flights from manchester to durban south africa",
"flights manchester to durban south africa",
"cheap flights to durban from london",
"flights from heathrow to durban",
"cheap flights london to durban south africa",
"flights from heathrow to durban south africa",
"flights from london heathrow to durban south africa",
"london heathrow to durban flights",
"manchester to durban",
"heathrow to durban",
"direct flights from london to durban",
"direct flight to durban from uk",
"london durban direct flights",
"london heathrow to durban",
"london to capetown",
"manchester to capetown",
"flights to cape town from london",
"flights cape town to london",
"flights to cape town from manchester",
"cape town to manchester",
"london to capetown flights",
"cheap flights from manchester to cape town",
"cheap flights from london to cape town south africa",
"flights to cape town from manchester uk",
"flights london to cape town return",
"manchester to cape town direct flights",
"direct flights from london to cape town south africa",
"manchester to capetown flights",
"flights from manchester to cape town south africa",
"direct flights to cape town from manchester uk",
"cheap flights from manchester to cape town south africa",
"cheap flights london to capetown",
"direct flights from manchester to cape town south africa",
"london to abidjan",
"london to abidjan flights",
"flight london abidjan",
"manchester to abidjan",
"cheap flights to abidjan from london",
"london to johannesburg",
"flights to johannesburg from london",
"cheap flights to johannesburg from london",
"london heathrow to johannesburg",
"cheap flights from london to johannesburg south africa",
"london to johannesburg direct flights",
"virgin flights to johannesburg from london",
"gatwick to johannesburg",
"london to jhb",
"flights from london heathrow to johannesburg",
"flights from london to johannesburg south africa",
"london johannesburg cheap flights",
"uk to johannesburg flights",
"london heathrow to johannesburg flights",
"flights from london to johannesburg cheap",
"heathrow to jhb",
"heathrow to joburg",
"from london to johannesburg flights",
"fly east london to johannesburg",
"london gatwick to johannesburg flights",
"direct flights london johannesburg",
"flight from johannesburg to east london south africa",
"manchester to johannesburg direct flights",
"direct flights from manchester to johannesburg",
"cheap flights from manchester to south africa johannesburg",
"london to entebbe",
"flights to entebbe from london",
"cheap flights to entebbe from london",
"cheap flights from entebbe to london",
"flight from london to entebbe uganda",
"manchester to entebbe",
"entebbe to manchester flights",
"manchester to entebbe flights",
"cheap flights to entebbe from manchester",
"cheap flights from manchester to entebbe",
"flights from entebbe to manchester",
"flights from manchester to entebbe",
"london to kinshasa",
"cheap flights to kinshasa from london",
"london to kinshasa brussels airlines",
"cheap flight to kinshasa from london heathrow",
"london kinshasa flights",
"flight london kinshasa",
"cheap flights to kinshasa from manchester",
"manchester to kinshasa flight",
"flights from manchester to kinshasa",
"london to luanda",
"cheap flights from london to luanda angola",
"london luanda flights",
"flights london to luanda angola",
"manchester to luanda",
"cheap flights from manchester to luanda angola",
"cheap flights to luanda from manchester",
"manchester luanda flights",
"flights from luanda to manchester",
"london to lusaka",
"flights from london to lusaka",
"flights london to lusaka zambia",
"flights to lusaka zambia from london",
"cheap flights from lusaka to london",
"flights from heathrow to lusaka zambia",
"heathrow to lusaka flights",
"manchester to lusaka",
"cheap flights from manchester to lusaka",
"flights to lusaka from manchester",
"flights from manchester to lusaka zambia",
"london to accra",
"cheap flights from accra to london",
"accra to london",
"flights to accra from london",
"flights from accra to london",
"cheap flights to accra from london",
"london to accra british airways",
"cheap flights from london to ghana accra",
"flights from london to ghana accra",
"flights from heathrow to accra ghana",
"klm london to accra",
"flights from heathrow to accra",
"london heathrow to accra flights",
"british airways from heathrow to accra",
"london to accra ghana",
"london heathrow to accra",
"manchester to accra",
"cheap flights from manchester to accra",
"cheap flights to accra ghana from manchester",
"flights manchester to accra",
"cheap flight from manchester to accra ghana",
"flights from manchester to accra ghana",
"cheap flights from accra to manchester",
"london to freetown",
"flights to freetown from london",
"cheap flights from london to freetown",
"london to lagos",
"cheap flights from lagos to london",
"flight from lagos to london",
"lagos to london flight price",
"london to lagos flight",
"cheap flights to lagos from london",
"flight ticket from lagos to london",
"direct flight from lagos to london",
"cheap flights to lagos nigeria from london",
"flights from london to lagos nigeria",
"ticket to lagos from london",
"cheap flight ticket from london to lagos",
"tickets from lagos to london",
"flight booking from lagos to london",
"cheap flights to london from lagos nigeria",
"tickets to lagos nigeria from london",
"flight booking from lagos to london",
"cheap flights to london from lagos nigeria",
"tickets to lagos nigeria from london",
"airfare lagos to london",
"flight cost from lagos to london",
"flight fare from lagos to london",
"fly london to lagos",
"manchester to lagos",
"cheap flights from manchester to lagos",
"cheap flights from manchester to lagos nigeria",
"manchester to lagos",
"lagos to manchester flight",
"cheap flights from manchester to lagos",
"flight from manchester to lagos",
"cheap flights from manchester to lagos nigeria",
"cheap flights from lagos nigeria to manchester uk",
    // Add more keywords...,
  ];
  
  export default keywords;
  