import React, { useState } from 'react';

import AsyncSelect from 'react-select/async';
import Service from '../../Service/Service';


// const filterColors = (inputValue) => {
//   return [{label:'REd',value:'1'},{label:'green',value:'2'}].filter((i) =>
//     i.label.toLowerCase().includes(inputValue.toLowerCase())
//   );
// };

const filterColors = (inputValue) => {



  if (!inputValue) {
    return []
  }

  return
};



const loadOptions = (
  inputValue,
  callback
) => {

  if (!inputValue) {
    setTimeout(() => { callback([]) }, 1000)
    return;
  }

  if (inputValue.length < 3) {
    setTimeout(() => { callback([]) }, 1000)
    return;
  }

  // Service.searchAirportDuffel({ keyword: inputValue }).then(response => {
  //   let data = response.data;
  //   let options = data.map(s => {
  //     return { label: s.name, value: s.iata_code ? s.iata_code : s.address.iata_city_code }
  //   }
  //   )
  //   callback(options);

  // }).catch(err => {
  //   callback([])
  // })

  Service.postAirportSearch({ keyword: inputValue }).then(response => {
    let data = response.data.data;
    let options = data.map(s => {
      return { label: s.name, value: s.iata_code ? s.iata_code : s.address.iata_city_code }
    }
    )
    callback(options);

  }).catch(err => {
    callback([])
  })
  // Service.postAirportSearch({ keyword: inputValue }).then(response => {
  //   let data = response.data;
  //   let options = data.map(s => {
  //     return { label: s.name, value: s.iataCode ? s.iataCode : s.address.cityCode }
  //   }
  //   )
  //   callback(options);

  // }).catch(err => {
  //   callback([])
  // })


};

export default ({ onChange }) => (
  <AsyncSelect cacheOptions loadOptions={loadOptions} defaultOptions onChange={onChange} classNames={{
    control: (state) =>
      'h-[50px] rounded-lg w-100'
  }} />
);