import React, { useEffect, useState } from "react";
import Logo from '../../assets/images/logo.png'
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { appConfig } from "../../appConfig";
import { footerData } from "./footerData";
import ReactWhatsappButton from "react-whatsapp-button";
import PAY from "../../assets/images/banner/pay.png";
import ICON from "../../assets/images/logo.png";
import TPP from "../../assets/images/banner/tpp.png";
import IATA from "../../assets/images/banner/iata.png";


const Footer = props => {

  const headRoute = appConfig.headRoute
  const [pkgtype, setpkgtype] = useState()
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {

    const catId = searchParams.get("category");
    const pt = searchParams.get("type");
    setpkgtype(pt);

  }, [location.pathname, searchParams]);

  const getNo = () => {
    if (pkgtype == 'umrah')
      return <ReactWhatsappButton
        countryCode="44"
        phoneNumber="07401490100"
      />
    else if (pkgtype == 'holiday')
      return <ReactWhatsappButton
        countryCode="44"
        phoneNumber="07401490100"
      />
    else
      return <ReactWhatsappButton
        countryCode="44"
        phoneNumber="07401490100"
      />
  }

  return (

    <div className="bg-secondary flex min-h-[100vh] md:xl:min-h-[60vh] 2xl:min-h-[40vh] lg:justify-center lg:items-center">
      <div className="w-full ">
        <div className="mb-10 max-md:py-10 mt-6">
          {/* Logo Image */}
          <div className="flex flex-col md:flex-row justify-center md:justify-between items-center mb-4 md:px-12">
            {/* Sky Sale Logo */}
            <div className="flex items-center gap-4">
              <img src={ICON} alt="Sky Sale Logo" className="h-[80px] mb-4" />

            </div>




            {/* Trustpilot Review Button */}
            <a src={TPP} href="https://uk.trustpilot.com/review/yourbusiness" target="_blank" rel="noopener noreferrer" className="flex items-center">
              <img src={TPP} alt="Sky Sale Logo" className="h-[80px] mb-4" />
            </a>
          </div>



          {/* Copyright and Other Information */}

          <hr className="my-7 mx-[5px] border-t-2 border-white w-[100%]" />

          {/* Quick Links Section */}
          <div className="flex flex-wrap justify-center gap-8 mb-8">
            <a href="/" className="text-white text-lg hover:text-gray-300 transition-colors duration-300">Home</a>
            <a href="/traveltips" className="text-white text-lg hover:text-gray-300 transition-colors duration-300">TravelTips</a>
            <a href="/tc" className="text-white text-lg hover:text-gray-300 transition-colors duration-300">Terms & Conditions</a>
            <a href="/privacypolicy" className="text-white text-lg hover:text-gray-300 transition-colors duration-300">Privacy Policy</a>
            <a href="/aboutus" className="text-white text-lg hover:text-gray-300 transition-colors duration-300">FAQ</a>
          </div>

          {/* Address and Logo Section */}
          <div className="flex items-center justify-between text-white mb-8">
            {/* Address Section */}


            <div className="text-center flex-1">
              <p className="text-lg font-bold mb-2">Our Address</p>
              <p className="text-md">30 Harwood Gate,</p>
              <p className="text-md">Blackburn,</p>
              <p className="text-md">BB1 5HP </p>
              <p className="text-md">skysales.uk@outlook.com</p>
            </div>



          </div>



          {/* <ReactWhatsappButton
                countryCode="44"
                phoneNumber="7365892753"
            /> */}

          {
            getNo()
          }

          <hr className="my-7 mx-[5px] border-t-2 border-white w-[100%]" />


          {/* Payment Logos */}
          <div className="flex flex-col items-center mt-8">
            {/* Payment Logos */}
            <div className="flex items-center mb-4">
              <img src={PAY} alt="Payment Logos" className="h-12" />
            </div>

            {/* Copyright Text */}
            <p className="text-white text-sm lg:text-md text-center">
              Copyright © 2025 Sky Sale. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>

  )
}
export default Footer