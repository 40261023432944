import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { routes } from './routes';
import Popup from './Popup';
import { Helmet } from 'react-helmet';
import keywords from "./keywords";

const router = createBrowserRouter([...routes]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <title>SkySale</title>
      <meta name="description" content="Cheap Flights from UK to Everywhere - Loweset AirFare" />
      <meta name="keywords" content={keywords.join(", ")} />
    </Helmet>
    <RouterProvider router={router} />
    {/* <App /> */}
    <Popup />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
