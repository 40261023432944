import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import { Circle, Facebook, Linkedin, Phone, Twitter } from "react-feather";

import Image from '../Contact/Image';

import InputSection from "../Home3/InputSection";
import { Link, useSearchParams } from "react-router-dom";
import Service from "../../Service/Service";
import InputSectionUmrah from "../Packagedetail/InputSectionUmrah";

const CardsSectionUmrah = ({ category_id, packages, rating }) => {
  const [showtoast, setShowToast] = useState(false);
  const [rating1, setRating] = useState(3);
  const [pack, setPack] = useState([])
  const [selectedPackage, setSelecteDPackage] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const pkgtype = searchParams.get("type");
  useEffect(() => {
    let p = packages.filter(x => x.category_id == category_id);
    setPack(p)
    setRating(rating)
  }, [category_id, packages])

  const getratingElement = (index) => {
    if (rating1 == 3) {
      return <div className="rating flex justify-center p-1">
        <input type="radio" name={`rating-${index}1`} className="mask mask-star-2 bg-secondary" disabled />
        <input type="radio" name={`rating-${index}2`} className="mask mask-star-2 bg-secondary" disabled />
        <input type="radio" name={`rating-${index}3`} className="mask mask-star-2 bg-secondary" checked disabled />

        <input type="radio" name={`rating-${index}4`} className="mask mask-star-2 bg-secondary" disabled />
        <input type="radio" name={`rating-${index}5`} className="mask mask-star-2 bg-secondary" disabled />
      </div>
    }
    else if (rating1 == 4) {
      return <div className="rating flex justify-center p-1">
        <input type="radio" name={`rating-${index}1`} className="mask mask-star-2 bg-secondary" disabled />
        <input type="radio" name={`rating-${index}2`} className="mask mask-star-2 bg-secondary" disabled />
        <input type="radio" name={`rating-${index}3`} className="mask mask-star-2 bg-secondary" disabled />

        <input type="radio" name={`rating-${index}4`} className="mask mask-star-2 bg-secondary" checked disabled />
        <input type="radio" name={`rating-${index}5`} className="mask mask-star-2 bg-secondary" disabled />
      </div>
    }
    else if (rating1 == 5) {
      return <div className="rating flex justify-center p-1">
        <input type="radio" name={`rating-${index}1`} className="mask mask-star-2 bg-secondary" disabled />
        <input type="radio" name={`rating-${index}2`} className="mask mask-star-2 bg-secondary" disabled />
        <input type="radio" name={`rating-${index}3`} className="mask mask-star-2 bg-secondary" disabled />

        <input type="radio" name={`rating-${index}4`} className="mask mask-star-2 bg-secondary" disabled />
        <input type="radio" name={`rating-${index}5`} className="mask mask-star-2 bg-secondary" checked disabled />
      </div>
    }
  }
  console.log(rating1 >= 4, rating1 == 5, category_id);
  return (
    <>


      {
        pack.length == 0 ? <span className="loading loading-ring loading-lg"></span> : pack.map((x, index) => {
          return (
            <div className='flex flex-col justify-center shadow-lg transition ease-in-out delay-450 rounded-lg hover:scale-110 hover:bg-primary '>
              <Image src={`${Service.baseURL}/uploads/${x.makkah_img1}`} classes='rounded-lg' />
              <p className='tracking-[2px] text-xl font-[500] leading-6 mt-2 p-2 text-center'>{x.name}</p>
              <p className='tracking-[1px] leading-6 p-1 text-center'>{pkgtype == 'umrah' ? `Perform Umrah with Best Price` : `Holidays in Best Price`}</p>
              {
                getratingElement(index)
              }

              <p className='tracking-[2px] text-2xl font-[700] leading-6 mt-1 p-2 text-center'>£{x.price}</p>

              <button className="btn bg-secondary text-white self-center mb-2 hover:bg-black" onClick={() => { }}><Link to={`/packagedetail?package=${x.uid}`}>View Detail</Link></button>
              <div className="grid grid-cols-2 gap-1 p-2">
                <button className="btn border-secondary bg-[transparent]  self-center mx-2 hover:bg-secondary hover:text-white text-[10px]" onClick={() => { window.location.href = 'tel:02045483242' }}><Phone className="h-[15px] w-[15px]" /> Call Now</button>
                <button className="btn border-secondary bg-[transparent]  self-center mx-2 hover:bg-secondary hover:text-white text-[10px]" onClick={() => {
                  setSelecteDPackage(x)
                  document.getElementById('my_modal_3').showModal()
                }}><Circle className="h-[15px] w-[15px]" /> Submit</button>

              </div>

            </div>

          )
        })

      }
      <dialog id="my_modal_3" className="modal">
        <div className="modal-box w-11/12 max-w-5xl bg-secondary">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-white">✕</button>
          </form>
          <InputSectionUmrah setShowToast={setShowToast} selectedPackage={selectedPackage} modal={true} />
        </div>
      </dialog>
    </>
  );
}

export default CardsSectionUmrah