import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "./swiper.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/effect-fade";





import B1 from "../../assets/images/banner/bg1122.jpeg";
import B2 from "../../assets/images/banner/umrahbg.jpeg";
import B3 from "../../assets/images/banner/holiday.jpeg";
import logo from "../../assets/images/logo.png";



import InputSection from "./InputSection";

import { Compass, CreditCard, MapPin, MonitorCog, PhoneCall, Share, Share2, Umbrella } from "lucide-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import InputSectionUmrah from "../Packagedetail/InputSectionUmrah";

const BannerSection = (props) => {

  const [page, setPage] = useState()
  const [pkgtype, setpkgtype] = useState()
  const navigate = useNavigate()
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {

    const catId = searchParams.get("category");
    const pt = searchParams.get("type");
    setpkgtype(pt);
    setPage(location.pathname)
  }, [location.pathname, searchParams]);

  const getNo = () => {
    if (pkgtype == 'umrah')
      return '020 4548 3242'
    else if (pkgtype == 'holiday')
      return '020 4548 3242'
    else
      return '020 4548 3242'
  }
  const getImg = () => {
    if (pkgtype == 'umrah')
      return B2
    else if (pkgtype == 'holiday')
      return B3
    else
      return B1
  }
  const getInputSection = () => {
    if (pkgtype == 'umrah')
      return <InputSectionUmrah />
    else if (pkgtype == 'holiday')
      return <InputSection />
    else
      return <InputSection />
  }
  const getHeadingPara = () => {
    if (pkgtype == 'umrah')
      return <p className="text-xl md:text-4xl text-white font-bold mt-12 md:mt-0">Cheap Umrah Deals </p>
    else if (pkgtype == 'holiday')
      return <p className="text-xl md:text-4xl text-white font-bold mt-12 md:mt-0">Cheap Holidays Deals </p>
    else
      return <p className="text-xl md:text-4xl text-white font-bold mt-12 md:mt-0">Cheap Flights <span className="text-secondary">One Simple Search!</span></p>
  }

  return (
    <div>
      <div className="relative w-full  ">
        <div className="absolute z-[-1] bg-[rgba(0,0,0,0.6)] w-full h-full"></div>
        <BackSwiper getImg={getImg} />
        <div className="  w-full flex justify-center flex-col">
          {/* <p className="tracking-[3px] text-2xl md:text-4xl font-[600]  leading-12 mt-7 text-white text-center" role="button" onClick={()=>{navigate('/')}}>
            Sky Sale UK
          </p> */}
          <div className="hidden md:flex flex-col md:flex-row w-[90%] md:w-[80%] mx-auto justify-center md:items-center md:justify-between mt-14 md:mt-5">
            {/* <img src={logo} className=" w-[200px] my-2" role="button" onClick={()=>{ window.location.href = '/'}}/> */}
            <div></div>
            <div className="flex mx-auto md:mx-0 md:ml-auto items-center gap-4 mt-5 md:mt-0" role="button" onClick={() => {
              if (pkgtype == 'umrah')
                window.location.href = 'tel:02045483242'
              else if (pkgtype == 'holiday')
                window.location.href = 'tel:02045483242'
              else
                window.location.href = 'tel:02045483242'
            }}>
              <PhoneCall className="text-white" />
              <p className="tracking-[2px] text-4xl md:text-2xl font-[700] md:font-[500] leading-8  text-center text-white blink-color">
                {getNo()}
              </p>
            </div>
          </div>


          <div className="md:hidden p-2 mt-3">
            {/* <p className="text-lg md:text-2xl text-white font-bold">Are you ready to travel</p> */}
            {getHeadingPara()}
            {getInputSection()}
          </div>


          <div className="w-[90%] md:w-[80%] mx-auto grid grid-cols-1 md:grid-cols-2 gap-5 mt-12">
            <div className="hidden md:block">
              {/* <p className="text-2xl text-white font-bold">Are you ready to travel</p> */}
              {getHeadingPara()}
              {getInputSection()}
            </div>

            <div className={location.pathname.includes('allpackages') || true ? `hidden` : ''}>


              <div className="w-full mt-7">
                <div className="flex gap-4 items-center mb-1">
                  <div className="rounded-lg bg-secondary flex justify-center items-center h-[40px] w-[40px]  md:h-[45px] md:w-[45px] drop-shadow-md drop-shadow-white">
                    <Umbrella className="text-white" />
                  </div>
                  <p className="text-xl md:text-2xl text-white font-bold">Financial Protection</p>
                </div>

                <p className="text-md md:text-lg text-white mt-2">Book with full confidence with us as all of our tickets protected.</p>
              </div>
              <hr className="text-white my-4" />



              <div className="w-full">
                <div className="flex gap-4 items-center mb-1">
                  <div className="rounded-lg bg-secondary flex justify-center items-center h-[40px] w-[40px]  md:h-[45px] md:w-[45px] drop-shadow-md drop-shadow-white">
                    <MonitorCog className="text-white" />
                  </div>
                  <p className="text-xl md:text-2xl text-white font-bold">Book With Confidence</p>
                </div>

                <p className="text-md md:text-lg text-white mt-2">Best Fare Guarantee. We offer the best fare possible and we will beat the quote you have got. We always stay in touch with our customers for their better asistance.</p>
              </div>


              <hr className="text-white my-4" />

              <div className="w-full">
                <div className="flex gap-4 items-center mb-1">
                  <div className="rounded-lg bg-secondary flex justify-center items-center h-[40px] w-[40px]  md:h-[45px] md:w-[45px] drop-shadow-md drop-shadow-white">
                    <CreditCard className="text-white" />
                  </div>
                  <p className="text-xl md:text-2xl text-white font-bold">Flexible Payment Options</p>
                </div>

                <p className="text-md md:text-lg text-white mt-2">Pay £50 as an initial payment and remaining balance later in installments without any extra cost. There are no hidden charges.</p>
              </div>



            </div>


            <div className="w-full justify-center items-center col-span-2">
              {/* <p className="tracking-[3px] text-2xl font-[600]  leading-12 mt-0 text-white text-center">
                5 Star Rated Company
              </p>
              <p className="tracking-[2px] text-xl font-[500] leading-8 mt-2 text-white text-center ">
                We are the only 5 star rated company on google and facebook.
              </p> */}
            </div>


          </div>


        </div>
      </div>





    </div>
  );
};

const BackSwiper = ({ getImg }) => {
  return (
    <div className="absolute z-[-2] w-full h-full ">
      <img src={getImg()} className="h-full w-full object-cover" />

    </div>
  );
};

export default BannerSection;
