import React, { useEffect, useState } from "react";
import service from "../../Service/Service";
import MyInput from "../Input/MyInput";
import { ThumbsUp } from "react-feather";
import Select from "./SelectAsync";
import { useNavigate } from "react-router-dom";
import './input.css'
import Popover from "./Popover";
import Spinner from "../../assets/images/icons/spinner2.gif";
import { PhoneCall } from "lucide-react";
//import { Duffel } from "@duffel/api";

const InputSection = ({ setShowToast, callby }) => {
  const [departure, setDeparture] = useState('');
  const [arrival, setArrival] = useState('');
  const [departure_name, setDepartureName] = useState('');
  const [arrival_name, setArrivalName] = useState('');
  const [date, setDate] = useState('');
  const [return_date, setReturnDate] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [accomodation, setAccomodation] = useState('');
  const [makkah_nights, setMakkahNights] = useState(1);
  const [nights, setNights] = useState(1);
  const [madinah_nights, setMadinahNights] = useState(1);
  const [rooms, setRooms] = useState('');
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [description, setDescription] = useState('');
  const [travelerSelect, setTravlerSelect] = useState(false);

  const [categories, setCategories] = useState([])
  const [packages, setPackages] = useState([])
  const [airportDep, setAirportDep] = useState([])
  const [airportArv, setAirportArv] = useState([])
  const [toast, showToast] = useState(false)
  const [error, setError] = useState('')
  const [flight_type, setFlightType] = useState('one-way')
  const [flight_class, setFlightClass] = useState('economy')
  const [submitLoading, setSubmitLoading] = useState(false)
  console.log(description);


  const flightClass = [
    { label: 'Economy', value: 'economy' },
    { label: 'Premium Economy', value: 'premium_economy' },
    { label: 'Business', value: 'business' },
    { label: 'First', value: 'first' },
    { label: 'Any', value: 'economy' }
  ]



  const getFlights = async () => {
    // const duffel = new Duffel({
    //   token: `duffel_live_N0QVRH_7Zhheun6QLl9RVar05DIea61VmZuU1s0n516`,
    //   debug: { verbose: true },
    // })

    // const offerRequestResponse = await duffel.offerRequests.create({
    //   slices: [
    //     {
    //       origin: 'NYC',
    //       destination: 'ATL',
    //       departure_date: '2021-06-21',
    //     },
    //   ],
    //   passengers: [{ type: 'adult' }],
    //   cabin_class: 'economy',
    //   return_offers: false,
    // })

    // console.log(offerRequestResponse.data)
  }

  const navigate = useNavigate()

  const flightTypesOptions = [
    {
      value: 'one-way', label: `One Way`
    },
    {
      value: 'return', label: `Return`
    }
  ]

  const getAllAirports = () => {
    service.getAllAirports().then(response => {
      let dep = response.data.filter(x => x.type == 'DEPARTURE')
      let arv = response.data.filter(x => x.type == 'ARRIVAL')

      setAirportDep(response.data);
      setAirportArv(response.data)
    })
  }

  useEffect(() => {

    getAllAirports();
    getFlights();
  }, [])




  const submit = () => {
    if (!departure) {
      setError('Please Select Departure');
      showToast(true);
      setTimeout(() => { showToast(false); }, 4000)
      return;
    }
    if (!arrival) {
      setError('Please Select Arrival');
      showToast(true);
      setTimeout(() => { showToast(false); }, 4000)
      return;
    }

    if (departure == arrival) {
      setError('Departure and Arrival must be different');
      showToast(true);
      setTimeout(() => { showToast(false); }, 4000)
      return;
    }
    if (!date) {
      setError('Please Select Date');
      showToast(true);
      setTimeout(() => { showToast(false); }, 4000)
      return;
    }
    if (!flight_type) {
      setError('Please Pick Flight Type');
      showToast(true);
      setTimeout(() => { showToast(false); }, 4000)
      return;
    }
    // if(!name){
    //   setError('Please Enter Full Name');
    //   showToast(true);
    //   setTimeout(()=>{showToast(false);},4000)
    //   return;
    // }
    // if(!phone){
    //   setError('Please Enter Phone');
    //   showToast(true);
    //   setTimeout(()=>{showToast(false);},4000)
    //   return;
    // }


    setSubmitLoading(true)
    let params = {
      departure,
      arrival,
      date,
      full_name: name,
      phone,
      adult: adults,
      children,
      infants,
      status: 'NEW',
      reviews: '',
      description: description,
      return_date,
      departure_name,
      arrival_name,
      flight_type,
      flight_class
    }


    service.postGetFlightOfferDuffel(params).then((ree) => {
      console.log(
        ree.data
      )
      localStorage.setItem('offer', JSON.stringify(ree.data))
      service.addBooking(params).then(x => {
        // document.getElementById('my_modal_4').showModal()
        // if(setShowToast){
        //   setShowToast(true);
        // }


        localStorage.setItem('booking', JSON.stringify(params))
        localStorage.setItem('bookingresult', JSON.stringify(x.data))
        setTimeout(() => {
          setSubmitLoading(false)
          window.location.href = `/flight-detail-search?package=0&call=${new Date().getTime()}`

        }, 300)
      }).catch(err => {
        console.log(err);
      })

    }).catch(err => {
      console.log(err)
    })







  }


  const getNo = () => {

    return '020 4548 3242'

  }

  return (
    <div className={callby ? "w-full mt-2 bg-secondary p-3 rounded-md" : "w-full mt-2"}>
      <div className="grid grid-cols-2 md:grid-cols-2 gap-1 ">
        {
          toast && <div className="absolute z-[100]">
            <div className="toast toast-top toast-end">
              <div className="alert alert-info bg-[red]">
                <span className="text-white text-lg">{error}</span>
              </div>
            </div>


          </div>
        }

        <div className={"col-span-full flex gap-3"}>

          <div class="flex items-center space-x-3">
            <label className={"cyberpunk-checkbox-label"}>
              <input type="checkbox" checked={flight_type == 'one-way'} className={"cyberpunk-checkbox"} onChange={(e) => {
                if (e.target.checked) {
                  setFlightType('one-way')
                }
              }} />
              One Way</label>
            <label class={"cyberpunk-checkbox-label"}>
              <input type="checkbox" checked={flight_type == 'return'} className={"cyberpunk-checkbox"} onChange={(e) => {
                if (e.target.checked) {
                  setFlightType('return')
                }
              }} />
              Return</label>
          </div>

        </div>
        <div className="p-1 flex flex-col col-span-2 md:col-span-1">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Departure Airport</label>
          <Select

            onChange={(e) => {
              setDeparture(e.value)
              setDepartureName(e.label)
            }} />
          {/* <select className="p-2 w-full max-w-xs rounded-sm" onChange={(e)=>{setDeparture(e.target.value)}}>
  
    <option disabled selected>Pick your Departure</option>
    {
      airportDep.map(x=>{
        return <option value={x.uid}>{x.name}</option>
      })
    }
    
  </select> */}
        </div>
        <div className="p-1 flex flex-col col-span-2 md:col-span-1">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Arrival Airport</label>
          <Select onChange={(e) => {
            setArrival(e.value)
            setArrivalName(e.label)
          }} />
          {/* <select className="p-2 w-full max-w-xs rounded-sm" onChange={(e)=>{setArrival(e.target.value)}}>
    <option disabled selected>Pick your Arrival</option>
    {
      airportArv.map(x=>{
        return <option value={x.uid}>{x.name}</option>
      })
    }
  </select> */}
        </div>
        {/* <div className="p-1 flex flex-col col-span-2 md:col-span-1">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Booking Type</label>

          <select className="p-3 w-full  rounded-md" onChange={(e) => { setFlightType(e.target.value) }}>
            <option disabled selected>Pick Booking type</option>
            {
              flightTypesOptions.map(x => {
                return <option value={x.value}>{x.label}</option>
              })
            }
          </select>
        </div> */}
        <div className={flight_type != 'return' ? `p-1 flex flex-col col-span-full` : "p-1 flex flex-col"}>
          <label className="tracking-[1px] font-[500] leading-6 text-white">Departure Date</label>
          <input type="date" placeholder="Type here" className="p-2 w-full h-[50px] rounded-lg" onChange={(e) => { setDate(e.target.value) }} />
        </div>
        {flight_type == 'return' && <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white">Return Date</label>
          <input type="date" placeholder="Type here" className="p-2 w-full  h-[50px] rounded-lg" onChange={(e) => { setReturnDate(e.target.value) }} />
        </div>}
        {/* <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 text-white">Full Name</label>
        <input type="text" placeholder="Type here" className="p-2 w-full max-w-xs h-[50px] rounded-lg" onChange={(e)=>{setName(e.target.value)}}/>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 text-white">Phone Number</label>
        <input type="Number" placeholder="Type here" className="p-2 w-full max-w-xs h-[50px] rounded-lg" onChange={(e)=>{setPhone(e.target.value)}}/>
        </div> */}








      </div>


      <div className="grid grid-cols-1 md:grid-cols-2">
        {/* <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Adult</label>
          <select className="p-2 w-full max-w-xs h-[50px] rounded-lg" onChange={(e) => { setAdults(e.target.value) }}>
            <option disabled selected>Adults</option>
            <option value={`0`}>0</option>
            <option value={`1`}>1</option>
            <option value={`2`}>2</option>
            <option value={`3`}>3</option>
            <option value={`4`}>4</option>
            <option value={`5`}>5</option>
          </select>

        </div> */}
        <div className="p-1">
          <Popover adults={adults} setAdults={setAdults} children={children} setChildren={setChildren} infants={infants} setInfants={setInfants} />

        </div>

        <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Flight Class</label>
          <select className="p-2 w-full h-[50px] rounded-lg" onChange={(e) => { setFlightClass(e.target.value) }}>

            {
              flightClass.map(x => {
                return <option value={x.value}>{x.label}</option>
              })
            }


          </select>
        </div>

        {/* <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Child</label>
          <select className="p-2 w-full max-w-xs h-[50px] rounded-lg" onChange={(e) => { setChildren(e.target.value) }}>
            <option disabled selected>Child</option>
            <option value={`0`}>0</option>
            <option value={`1`}>1</option>
            <option value={`2`}>2</option>
            <option value={`3`}>3</option>
            <option value={`4`}>4</option>
            <option value={`5`}>5</option>
          </select>
        </div> */}

        {/* <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Infants</label>
          <select className="p-2 w-full max-w-xs h-[50px] rounded-lg" onChange={(e) => { setInfants(e.target.value) }}>
            <option disabled selected>Infants</option>
            <option value={`0`}>0</option>
            <option value={`1`}>1</option>
            <option value={`2`}>2</option>
            <option value={`3`}>3</option>
            <option value={`4`}>4</option>
            <option value={`5`}>5</option>
          </select>
        </div> */}

      </div>

      {/* <div className="flex my-2 gap-2">
        <input type="checkbox"/>
        <p className="text-white text-md"> By proceeding, I acknowledge that I have read and agreed to Sky Sale UK Terms And Conditions and Privacy Policy statements.</p>
      </div> */}

      <div className="p-1">
        { }
        <button className="btn border-secondary rounded-xl bg-secondary hover:bg-secondary w-full my-5 text-white" onClick={submit}>{submitLoading ? <div
          class="w-10 h-10 border-4 border-t-secondary border-gray-300 rounded-full animate-spin"
        ></div> : `Find Cheap Flights`} </button>
      </div>


      <div className="flex flex-col md:flex-row w-[90%] md:w-[80%] mx-auto justify-center md:items-center md:justify-between md:mt-5 md:hidden">
        {/* <img src={logo} className=" w-[200px] my-2" role="button" onClick={()=>{ window.location.href = '/'}}/> */}
        <div></div>
        <div className="flex mx-auto md:mx-0 md:ml-auto items-center gap-4 mt-5 md:mt-0" role="button" onClick={() => {

          window.location.href = 'tel:02045483242'

        }}>
          <PhoneCall className="text-white" />
          <p className="tracking-[2px] text-4xl md:text-2xl font-[700] md:font-[500] leading-8  text-center text-white blink-color">
            {getNo()}
          </p>
        </div>
      </div>
      <dialog id="my_modal_4" className="modal">
        <div className="modal-box w-11/12 max-w-5xl bg-secondary">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-white" onClick={() => { window.location.reload() }}>✕</button>
          </form>
          <p className="text-xl text-white p-8"><ThumbsUp /> Booking Submitted</p>
        </div>
      </dialog>
    </div>

  )
}



export default InputSection