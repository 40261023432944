import React, { useEffect, useState } from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';



import HeadingSep from "../../assets/images/banner/heading-sep.png";
import CardsSection from "./CardsSectionUmrahHP";


const CategorySectionUmrah = ({ data, packages }) => {
  const [rating, setRating] = useState(3);

  console.log(window.location);

  const checkPackages = () => {
    let p = packages.filter(x => x.category_id == data.uid);
    return p.length
  }

  return (
    checkPackages() == 0 ? '' :
      <div className=" flex flex-col justify-center items-center">

        {/* <p className="tracking-[3px] text-5xl font-[600]  leading-12 p-4 text-black text-center">{data.name}</p> */}
        {/* <img src={HeadingSep} /> */}
        {/* {
          window.location.pathname != '/allpackages' && <div className="w-full flex justify-end">
            <a href={`/allpackages?category=${data.uid}`} className="text-lg font-[500] text-secondary">View All</a>
          </div>
        } */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 lg:gap-5 my-10 px-10">
          <CardsSection category_id={data.uid} packages={packages} rating={data.rating} />

        </div>
      </div>
  )
}






export default CategorySectionUmrah