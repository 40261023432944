import React, { useState } from "react";

const Popover = ({ adults, setAdults, children, setChildren, infants, setInfants }) => {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle the visibility of the popover
    const togglePopover = () => {
        setIsOpen(!isOpen);
    };

    // Close the popover when clicking outside
    const closePopover = (e) => {
        const popoverContainer = document.getElementById('containerpopver');
        if (popoverContainer && e.target.id != 'popoverTrigger' && !popoverContainer.contains(e.target) && isOpen) {
            setIsOpen(false);
        }
    };

    // Add event listener for clicks outside the popover
    React.useEffect(() => {
        document.addEventListener('click', closePopover);
        return () => {
            document.removeEventListener('click', closePopover);
        };
    }, [isOpen]);

    return (
        <div className="relative inline-block text-left w-full">
            <label className="tracking-[1px] font-[500] leading-6 text-white"> Passengers</label>
            <button
                id="popoverTrigger"
                onClick={togglePopover}
                className="bg-white text-left h-[50px] px-2 w-full py-2 rounded-md focus:outline-none"
            >
                {adults > 0 || children > 0 || infants > 0 ? `${adults} Adults, ${children} Children, ${infants} Infants` : `Passengers`}
            </button>


            {isOpen && (
                <div className="popover-container absolute mt-2 w-[82vw] md:w-[400px] bg-white border border-gray-300 rounded-md shadow-lg z-10" id="containerpopver">
                    <div className="py-2 px-4 text-gray-800 space-y-2">
                        {/* <div className="flex gap-5 justify-between">
                            <div className="basis-2/3">
                                <p className="font-bold text-[16px]">Adults</p>
                                <p className=" text-[16px]">18+</p>
                            </div>
                            <div className="flex justify-center items-center">
                                <button className="bg-black text-white h-[30px] w-[30%] rounded-md font-bold" onClick={(e) => {
                                    if (!isNaN(adults))
                                        setAdults(adults + 1);
                                }}>
                                    +
                                </button>
                                <input type="text" className="w-[40%]" value={adults} onChange={(e) => {
                                    if (!isNaN(e.target.value)) {
                                        setAdults(e.target.value)
                                    }
                                }} />
                                <button className="bg-black text-white h-[30px] w-[30%] rounded-md font-bold" onClick={(e) => {
                                    if (!isNaN(adults) && adults >= 1)
                                        setAdults(adults - 1);
                                }}>
                                    -
                                </button></div>

                        </div> */}
                        <PassengerTypeSections passenger={adults} setPassenger={setAdults} text1={`Adults`} text2={`18 +`} />
                        <PassengerTypeSections passenger={children} setPassenger={setChildren} text1={`Children`} text2={`Under 18 and Above 2`} />
                        <PassengerTypeSections passenger={infants} setPassenger={setInfants} text1={`Infants`} text2={`Under 2`} />
                    </div>
                </div>
            )}
        </div>
    );
};



const PassengerTypeSections = ({ passenger, setPassenger, text1, text2 }) => {
    return (
        <div className="flex gap-5 justify-between">
            <div className="basis-2/3">
                <p className="font-bold text-[16px]">{text1}</p>
                <p className=" text-[16px]">{text2}</p>
            </div>
            <div className="flex justify-center items-center">
                <button className="bg-black text-white h-[30px] w-[30%] rounded-md font-bold" onClick={(e) => {
                    if (!isNaN(passenger))
                        setPassenger(passenger + 1);
                }}>
                    +
                </button>
                <input type="text" className="w-[40%] h-[30px] text-center" value={passenger} onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                        setPassenger(e.target.value)
                    }
                }} />
                <button className="bg-black text-white h-[30px] w-[30%] rounded-md font-bold" onClick={(e) => {
                    if (!isNaN(passenger) && passenger >= 1)
                        setPassenger(passenger - 1);
                }}>
                    -
                </button></div>

        </div>
    )
}

export default Popover;
