import axios from "axios"

const baseURL = `https://sapis.skysale.co.uk`
//const baseURL = `http://localhost:3072`
// POST  arx1WvT9
const postExecutionHOF = (api, data, id) => {

    // let URL = "http://localhost:3002"
    if (id) { return axios.post(`${baseURL}${api}?id=${id}`, data) }

    return axios.post(`${baseURL}${api}`, data)
}

// GET
// Get Manage HOF
const getExecutionHOF = (api) => {

    // let URL = "http://localhost:3002"
    return axios.get(`${baseURL}${api}`)
}




const uploadImage = (data) => postExecutionHOF('/upload_file', data)
const login = (data) => postExecutionHOF('/auth/login', data)
const deleteBlog = (data) => postExecutionHOF('/delete-blog', data)
const getCategories = (data) => getExecutionHOF('/get-categories')
const addBlog = (data) => postExecutionHOF('/add-blog', data)
const getAllBlogs = (data) => getExecutionHOF('/get-blogs')
const addEvent = (data) => postExecutionHOF('/add-event', data)
const getAllEvents = (data) => getExecutionHOF('/get-events')
const deleteEvent = (data) => postExecutionHOF('/delete-event', data)


const addAirport = (data) => postExecutionHOF('/add-airport', data)
const deleteAirport = (data) => postExecutionHOF('/delete-airport', data)
const getAllAirports = (data) => getExecutionHOF('/get-airports')
const addCategory = (data) => postExecutionHOF('/add-category', data)
const deleteCategory = (data) => postExecutionHOF('/delete-category', data)
const getAllCategory = (data) => getExecutionHOF('/get-all-category')
const addHotel = (data) => postExecutionHOF('/add-hotel', data)
const deletehotel = (data) => postExecutionHOF('/delete-hotel', data)
const getAllhotel = (data) => getExecutionHOF('/get-all-hotel')
const addPackage = (data) => postExecutionHOF('/add-package', data)
const deletepackage = (data) => postExecutionHOF('/delete-package', data)
const getAllpackage = (data) => getExecutionHOF('/get-all-package')
const getAllpackageByCatId = (data) => getExecutionHOF('/get-all-package-by-catid?category_id=' + data.category_id)
const getpackagebyid = (data) => getExecutionHOF('/get-package-by-id?' + `uid=${data.uid}`)
const addPackageUmrah = (data) => postExecutionHOF('/umrah/add-package', data)
const deletepackageUmrah = (data) => postExecutionHOF('/umrah/delete-package', data)
const getAllpackageUmrah = (data) => getExecutionHOF('/umrah/get-all-package')
const getAllpackageByCatIdUmrah = (data) => getExecutionHOF('/umrah/get-all-package-by-catid?category_id=' + data.category_id)
const getpackagebyidUmrah = (data) => getExecutionHOF('/umrah/get-package-by-id?' + `uid=${data.uid}`)
const addBooking = (data) => postExecutionHOF('/add-booking', data)
const addContact = (data) => postExecutionHOF('/add-contact', data)
const postGetFlights = (data) => postExecutionHOF('/get-flights', data)
const postAirportSearch = (data) => postExecutionHOF('/airport-search', data)
const postGetFlightOfferDuffel = (data) => postExecutionHOF('/get-flight-offer-duffel', data)
const getAllparas = (data) => getExecutionHOF('/get-paras')
const getSearchFlights = (data) => getExecutionHOF(`/search-flights?arrival=${data.arrival}&departure=${data.departure}`)
const getFlightsDetailsByOfferId = (data) => getExecutionHOF(`/get-flights-by-offerid?offerid=${data.offerid}`)

let configDuffel = {
    method: 'get',
    maxBodyLength: Infinity,
    url: '',
    headers: {
        'Authorization': 'Bearer ',
        'Duffel-Version': 'v2'
    }
};

const searchAirportDuffel = ({ keyword }) => {
    // let config = {
    //     method: 'get',
    //     maxBodyLength: Infinity,
    //     url: 'https://api.duffel.com/places/suggestions?query=lahor',
    //     headers: { 
    //       'Authorization': 'Bearer duffel_live_N0QVRH_7Zhheun6QLl9RVar05DIea61VmZuU1s0n516', 
    //       'Duffel-Version': 'v2'
    //     }
    //   };

    return new Promise((resolve) => {
        let config = JSON.parse(JSON.stringify(configDuffel));
        config.url = `https://api.duffel.com/places/suggestions?query=${keyword}`
        config.method = `get`

        axios.request(config).then(ress => {
            console.log(ress.data)
            resolve(ress.data);
        })
    })


}



export default {
    addCategory,
    uploadImage,
    login,
    getCategories,
    addBlog,
    getAllBlogs,
    deleteBlog,
    addEvent,
    getAllEvents,
    deleteEvent,
    addAirport,
    getAllAirports,
    deleteAirport,
    deleteCategory,
    getAllCategory,
    addHotel,
    deletehotel,
    getAllhotel,
    addPackage,
    deletepackage,
    getAllpackage,
    getpackagebyid,
    addBooking,
    addContact,
    getAllpackageByCatId,
    postGetFlights,
    getAllparas,
    postAirportSearch,
    getSearchFlights,
    baseURL,
    addPackageUmrah,
    deletepackageUmrah,
    getAllpackageUmrah,
    getAllpackageByCatIdUmrah,
    getpackagebyidUmrah,
    searchAirportDuffel,
    postGetFlightOfferDuffel,
    getFlightsDetailsByOfferId
}

